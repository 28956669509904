import React from 'react'
import { useNavigate } from 'react-router-dom';

const Pagenotfound = () => {
  const usenavigate=useNavigate()
  setTimeout(() => {
    usenavigate('/')
  }, 2000);
  return (
    <>
    
        <h1 style={{marginTop:"5rem"}}>
            Error Page 
            <br />
            <div class="spinner-border text-success fs-1" role="status">
                <span class="sr-only"></span>
            </div>
        </h1>
    </>
  )
}

export default Pagenotfound
