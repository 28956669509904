import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Card from './ComponentPage/Card';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import ReactPaginate from 'react-paginate';

let paragraphstyle={
  WebkitLineClamp:3,
  WebkitBoxOrient:"vertical",
  overflow:'hidden',
  display:'-webkit-box',
  fontSize:"10px"
}

function Blog() {
  const [isOpen, setisOpen] = useState(false)
  const [viewlocal,SetViewlocal]=useState([]);
  const [dataLength, setdataLength] = useState(0)
  const [RangeData, setRangeData] = useState([])
  // let CurrentPost=[]
  const [start, setstart] = useState(0)
  const [End, setEnd] = useState(3)

  useEffect(() => {
      getTotal()
      // console.log(RangeData,'RDa');
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
  }, [])

  const getTotal=()=>{
      axios.get('https://ayurveda.pythonanywhere.com/ModelApi/v1/BlogViewSet/')
      .then((res)=>{
          setdataLength(res.data.length)
          SetViewlocal(res.data.reverse())
          // console.log(viewlocal,'viewlocal');
          // setRangeData(res.data.reverse().slice(0,3))

          if (start<=0){
              setRangeData(res.data.slice(0,3))
          }
        
        

      })

    }


    const NextPage=()=>{
      // console.log(start,End,'start,end NextButton');
      setstart(End)
      setEnd(End+3)
      setRangeData(viewlocal.slice(start,End));
      
      if (End>=dataLength) {
          setstart(dataLength-3)
          setEnd(dataLength)
          setRangeData(viewlocal.slice(dataLength-3,dataLength));
      }

    }

    const BackPage=()=>{
      // console.log(start,End,'start,end BackPage');
      setEnd(start)
      setstart(start-3)
      setRangeData(viewlocal.slice(start,End));
      
      if (start<=1 ) {
          setstart(0)
          setEnd(3)
          setRangeData(viewlocal.slice(start,End));
          console.log(start,End,'start,end IF');
      }
    }

  const ImmunityWellness=()=>{
    Swal.fire({
      width:"100%",
      title: "<br/>Immunity Wellness",
      text: "",
      // imageUrl: "https://placeholder.pics/svg/300x1500",
      imageUrl: "https://krishnaayurved.com/cdn/shop/collections/Category_banner-01_720x.jpg?v=1662485951",
      // imageWidth: '85%',
      
      showCloseButton: true,
  })
  }

  const Diabetes=()=>{
    Swal.fire({
      width:"100%",
      
      title:"Diabetic Wellness",
      html:'',
      imageUrl: "https://krishnaayurved.com/cdn/shop/collections/Category_banner-04_720x.jpg?v=1622649044",
      // imageWidth: '85%',
      
      showCloseButton: true,
  })
  }
  const _useNavigate=useNavigate()
  const Digestive=()=>{
    
    _useNavigate('./BlogExplorer/3')
  }
  

  return (
    <>
    <div className="container-fluid" style={{marginTop:"5rem"}}>
      <h4 className='container btn btn-success w-75'>Daily Blog</h4>
          <div className="row">
          
            <center>
                {RangeData.map((item,index)=>
                    <div className="col-md-10  p-0" key={index}>
                        <div align='center' className=' p-0 m-0' key={index}>
                            <Card id={item.id} Photo={item.Photo} BlogTitle={item.BlogTitle} isOpen={isOpen} BlogDiscription={item.BlogDiscription} setisOpen={setisOpen} paragraphstyle={paragraphstyle}/>
                            <div align='center' className='mb-3'>
                            </div>
                        </div>
                    </div>
                    )
                  }
                  <button onClick={BackPage} className='btn btn-success rounded-0'>Back</button>&ensp;|| &ensp;
                  <button onClick={NextPage} className='btn btn-success rounded-0'>Next</button>
                  {/* <div  onChange={handlerPageClick} style={{margin: 'auto',  width: '50%', }}>
                  <div className='text-primary'>
                    
                  </div>
                  <br /><br />
                  
                  <ReactPaginate previousLabel="<Back"
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    pageCount={total}
                    marginPagesDisplayed={2} //view number page
                    pageRangeDisplayed={2} //first number to page .... phala
                    onPageChange={handlerPageClick} //function get next link 
                    containerClassName={"pagination"} //css view
                    pageClassName={'page-item '} //size style
                    pageLinkClassName={'page-link'} //every page link view number 
                    breakClassName={'page-item '}
                    breakLinkClassName={'page-link'}
                    activeClassName={'active '}  //view current page bg-active

                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    nextLabel="Next>" />
                  </div> */}
              </center>
            
          </div>
            

            {/* Card */}
            <br /><br />
<div className="container">
{/* Mobile view card */}

  <div className="row p-0 m-0 mb-0 ">
        <div className="col-4 mb-3">
          <Link to='/BlogExplorer/5' >
          <div className="card m-0 p-0" style={{width: '100%', height:'170px'}}>
            <img src="https://krishnaayurved.com/cdn/shop/collections/Category_banner-01_720x.jpg?v=1662485951" width="100%" height='140px' className="card-img-top" alt="..." />
            <i style={{fontSize:"0.8rem"}}><b>Immunity Wellness</b></i>
          </div>
          </Link>
        </div>
        <div className="col-4 mb-3" >
        <Link to='/BlogExplorer/3' >
          <div  className="card m-0 p-0" style={{width: '100%', height:'170px'}}>
            <img src="https://d2jx2rerrg6sh3.cloudfront.net/images/news/ImageForNews_751597_16873373339751943.jpg" width="100%" height='140px' className="card-img-top" alt="..." />
              
            <i style={{fontSize:"0.8rem"}}><b>Digestive</b></i>
              
          </div>

          </Link>
        </div>


        <div className="col-4 mb-3" >
        <Link to='/BlogExplorer/2' >
          <div className="card m-0 p-0" style={{width: '100%', height:'170px'}}>
            <img src="https://krishnaayurved.com/cdn/shop/collections/Category_banner-04_720x.jpg?v=1622649044" width="100%" height='140px' className="card-img-top" alt="..." />
              
            <i style={{fontSize:"0.8rem"}}><b>Diabetic Wellness</b></i>
              
          </div>
          </Link>
        </div>


     <div className="col-4 mb-3" >
        <Link to='/BlogExplorer/6'>
            <div className="card m-0 p-0" style={{width: '100%', height:'170px'}}>
              <img src="Liver.jpg" width="100%" height='140px' className="card-img-top" alt="..." />
              <i style={{fontSize:"0.8rem"}}><b>Liver Care</b></i>
              
          </div>
        </Link>
        </div>

         


        <div className="col-4 mb-3" >
        <Link to='/BlogExplorer/7'>
            <div className="card m-0 p-0" style={{width: '100%', height:'170px'}}>
              <img src="Heartcare.jpg" width="100%" height='140px' className="card-img-top" alt="..." />
              <i style={{fontSize:"0.8rem"}}><b>Heart Care</b></i>
              
          </div>
          </Link>
        </div>



        <div className="col-4 mb-3" >
        <Link to='/BlogExplorer/8'>
            <div className="card m-0 p-0" style={{width: '100%', height:'170px'}}>
              <img src="https://www.gigadocs.com/blog/wp-content/uploads/2020/04/23123426-0-A_new_study_from_Aarhus_University_Hospital_in_Denmark_has_found-a-13_1584957855034.jpg" width="100%" height='140px' className="card-img-top" alt="..." />
              <i style={{fontSize:"0.8rem"}}><b>Weight Loss</b></i>
          </div>
          </Link>
        </div>
   

        <div className="col-4 mb-3" >
                <Link to=''>
                    <div className="card m-0 p-0" style={{width: '100%', height:'170px'}}>
                      <img src="https://www.biospectrumindia.com/uploads/articles/2019_will_transform_how_mental_health_care_is_delivered-16074.jpg" width="100%" height='140px' className="card-img-top" alt="..." />
                      <i style={{fontSize:"0.8rem"}}><b>Stress & Anxiety </b></i>
                      
                  </div>
              </Link>
                </div>


        <div className="col-4 mb-3" >
        <Link to='/BlogExplorer/10'>
                    <div className="card m-0 p-0" style={{width: '100%', height:'170px'}}>
                      <img src="https://yourptm.com/wp-content/uploads/2020/12/spine-e1607702678326-1280x720.png" width="100%" height='140px' className="card-img-top" alt="..." />
                      <i style={{fontSize:"0.8rem"}}><b>Joint Pain</b> </i>
                      
                  </div>
                  </Link>
                </div>


        <div className="col-4 mb-3" >
        <Link to=''>
                    <div className="card m-0 p-0" style={{width: '100%', height:'170px'}}>
                      <img src="https://glovenation.com/cdn/shop/articles/blog_header-_latex_allergy.png?v=1686319902" width="100%" height='140px' className="card-img-top" alt="..." />
                      <i style={{fontSize:"0.8rem"}}><b>Skin Allergy</b></i>
                      
                  </div>
        </Link>
                </div>

        

        </div>

 {/* Mobile view card  ENd*/}
</div>
{/* Card End */}

      </div>
    </>
  );
}

export default Blog;