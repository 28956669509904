import { useEffect, useRef, useState } from "react";

function Short({ short, shortContainerRef }) {
  const playPauseRef = useRef();
  const videoRef = useRef();

  const [isPlaying, setIsPlaying] = useState(true);
  const [isMuted, setIsMuted] = useState(false);
  // const [isLiked, setIsLiked] = useState(short.reaction.isLiked);

  useEffect(() => {
    shortContainerRef.current.addEventListener("scroll", handleVideo);
    setIsPlaying(!videoRef.current.paused);
    setIsMuted(videoRef.current.muted);
    window.addEventListener("blur", () => {
      if (isActiveVideo(videoRef)) {
        videoRef.current.pause();
        setIsPlaying(false);
      }
    });

    window.addEventListener("focus", () => {
      if (isActiveVideo(videoRef)) {
        videoRef.current.play();
        setIsPlaying(true);
      }
    });
  }, [shortContainerRef]);

  async function handleVideo() {
    const videoTop = videoRef.current.getBoundingClientRect().top;

    if (videoTop > 0 && videoTop <= 150) {
      try {
        await videoRef.current.play();
        setIsPlaying(true);
      } catch (error) {
        setIsPlaying(false);
        videoRef.current.pause();
      }
    } else {
      videoRef.current.currentTime = 0;
      videoRef.current.pause();
    }
  }

  return (
    <div className="reel">
      <div className="reel-video" >
        <div className="video" >
          {/* <div className="video-con"> */}
          <video
            ref={videoRef}
            onClick={function (e) {
              if (!isPlaying) {
                videoRef.current.play();
                setIsPlaying(true);
              } else {
                videoRef.current.pause();
                setIsPlaying(false);
              }
            }}
            disableRemotePlayback
            playsInline
            loop
            src={short.Video}
          ></video>
          {/* </div> */}
          <div className="controls">
            <span onClick={() => {
                  if (!isPlaying) {

                    videoRef.current.play();
                    setIsPlaying(true);
                  } 
                  else {
                  videoRef.current.pause();
                  setIsPlaying(false);
                }
              }}
            >
              <ion-icon
                name={`${isPlaying ? "pause" : "play"}-outline`}
              ></ion-icon>
            </span>
            <span
              onClick={() => {
                videoRef.current.muted = !isMuted;
                setIsMuted(!isMuted);
              }} >
              
              <ion-icon name={`volume-${isMuted ? "mute" : "medium"}-outline`} >
                
              </ion-icon>
            </span>
          </div>
          {/* Play Pause Button */}
          <div  ref={playPauseRef} onClick={() => { videoRef.current.play(); setIsPlaying(true); }} className={`play-pause ${isPlaying ? "" : "show-play-pause"}`}>
            <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" className="bi bi-pause-circle-fill" viewBox="0 0 16 16">
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M6.25 5C5.56 5 5 5.56 5 6.25v3.5a1.25 1.25 0 1 0 2.5 0v-3.5C7.5 5.56 6.94 5 6.25 5m3.5 0c-.69 0-1.25.56-1.25 1.25v3.5a1.25 1.25 0 1 0 2.5 0v-3.5C11 5.56 10.44 5 9.75 5"/>
            </svg>
          </div>
          {/* Play Pause Button */}
          
          <div className="foot">
            <div className=" text-white mb-5 bg-dark rounded-5">{short.title}</div>
          
          </div>
        </div>
        
      </div>
    </div>
  );
}

function isActiveVideo(videoRef) {
  const videoTop = videoRef.current.getBoundingClientRect().top;
  return videoTop > 0 && videoTop <= 150;
}

export default Short;
