import React, { useEffect, useState } from 'react'

import { Link } from 'react-router-dom'
// import axios from 'axios'

import './Headres.css'
import axios from 'axios'
import { renderToString } from 'react-dom/server'
// background css liner grident
// https://codioful.com/cherish-portray

const Headres = () => {
  const [AddressCheck, setAddressCheck] = useState('')
  useEffect(() => {
    
// ------------------------------------
    axios.get("https://ayurveda.pythonanywhere.com/ModelApi/v1/SiteVisiterViewSet/")
    .then((res) => {
      let Addresssa
      
    
      LoadCount(parseInt(res.data.reverse()[0].CountViewer)+1)
    })
    .catch((err) => {
      console.log(err,'err In Header');
    });

    
  }, [])


  async function LoadCount(a) {
    console.log(a,'---------');
    const res = await axios.get("https://api.ipify.org/?format=json");
    // console.log();
    axios.post('https://ayurveda.pythonanywhere.com/ModelApi/v1/SiteVisiterViewSet/',{"CountViewer":a,'CountName':res.data.ip})
    .then((res) => {
      // console.log(res,'-----------');
    })
    .catch((err) => {
      // console.log(err, "err 2 in  Header ");
    });
  }
  return (
    <>

{/* <td className='rounded-circle pt-1  bg-white' width={'25%'}></td> */}


<nav className="navbar nnnnnnnnnnnnn navbar-expand-lg bg-light  m-1 w-100" style={{position:"fixed",top:0,
    background: 'linear-gradient(125deg, #FDFF9C 0%, #0500FF 100%), linear-gradient(180deg, #D3D3D3 0%, #161616 100%), linear-gradient(310deg, #00F0FF 0%, #00F0FF 20%, #0017E3 calc(20% + 1px), #0017E3 40%, #000F8F calc(40% + 1px), #000F8F 70%, #00073F calc(70% + 1px), #00073F 100%), linear-gradient(285deg, #FFB6B9 0%, #FFB6B9 35%, #FAE3D9 calc(35% + 1px), #FAE3D9 45%, #BBDED6 calc(45% + 1px), #BBDED6 65%, #61C0BF calc(65% + 1px), #61C0BF 100%)',backgroundBlendMode: "overlay, overlay, exclusion, normal"}}>
  <div className="container-fluid">
    <a className="navbar-brand m-0 p-0 ms-0" href="/">
          <img src="/Capture.PNG" alt="" width={"203px"} className='rounded-5 ' />
    </a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarNav">
      <strong>
      <ul align='left' className="navbar-nav">
        <li className="nav-item ms-3">
          <div className=''>
              <a className="nav-link active  " aria-current="page" href="/" >
                 <span className='text-primary fs-4'>|</span>Home</a>
          </div>
        </li>
        
        
        <li className="nav-item ms-3 ">
          <a className="nav-link active " href="/Blog" >
          <span className='text-primary fs-4'>|</span>Blog</a>
        </li>

        <li className="nav-item ms-3 ">
          <a className="nav-link active" href="/Products" >
          <span className='text-primary fs-4'>|</span>Shop</a>
        </li>

        <li className="nav-item ms-3 ">
          <a className="nav-link active" href="/ShortVideos" >
          <span className='text-primary fs-4'>|</span>Yoga Videos</a>
        </li>

        <li className="nav-item ms-3 ">
          <a className="nav-link active" href="/HomeRemedies" >
          <span className='text-primary fs-4'>|</span>Home Remedies</a>
        </li>

        <li className="nav-item ms-3 ">
          <a className="nav-link active" href='/CustomerCare' >
          <span className='text-primary fs-4'>|</span>Help Center</a>
        </li>

      </ul>
      </strong>
    </div>
  </div>
</nav> 






<div className='mobileviewHeader p-3' style={{background: 'linear-gradient(125deg, #FDFF9C 0%, #0500FF 100%), linear-gradient(180deg, #D3D3D3 0%, #161616 100%), linear-gradient(310deg, #00F0FF 0%, #00F0FF 20%, #0017E3 calc(20% + 1px), #0017E3 40%, #000F8F calc(40% + 1px), #000F8F 70%, #00073F calc(70% + 1px), #00073F 100%), linear-gradient(285deg, #FFB6B9 0%, #FFB6B9 35%, #FAE3D9 calc(35% + 1px), #FAE3D9 45%, #BBDED6 calc(45% + 1px), #BBDED6 65%, #61C0BF calc(65% + 1px), #61C0BF 100%)',backgroundBlendMode: "overlay, overlay, exclusion, normal"}}> 
<table width={'100%'}>
<tbody>
  <tr >
    <td width={'20%'} >
          <Link to='/'><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bg-success rounded p-1 text-white bi bi-house-door-fill" viewBox="0 0 16 16">  <path d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5"/></svg></Link>
          <p>Home</p>
    </td>

    <td width={'20%'} >
        <Link to='Blog' ><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bg-success rounded p-1 text-white  bi bi-newspaper" viewBox="0 0 16 16">  <path d="M0 2.5A1.5 1.5 0 0 1 1.5 1h11A1.5 1.5 0 0 1 14 2.5v10.528c0 .3-.05.654-.238.972h.738a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 1 1 0v9a1.5 1.5 0 0 1-1.5 1.5H1.497A1.497 1.497 0 0 1 0 13.5zM12 14c.37 0 .654-.211.853-.441.092-.106.147-.279.147-.531V2.5a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0-.5.5v11c0 .278.223.5.497.5z"/>  <path d="M2 3h10v2H2zm0 3h4v3H2zm0 4h4v1H2zm0 2h4v1H2zm5-6h2v1H7zm3 0h2v1h-2zM7 8h2v1H7zm3 0h2v1h-2zm-3 2h2v1H7zm3 0h2v1h-2zm-3 2h2v1H7zm3 0h2v1h-2z"/></svg></Link>
        <p>Blogs</p>
    </td>
    
    <td width={'20%'} >
         <Link to='Products'><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bg-success rounded p-1 text-white bi bi-shop" viewBox="0 0 16 16">  <path d="M2.97 1.35A1 1 0 0 1 3.73 1h8.54a1 1 0 0 1 .76.35l2.609 3.044A1.5 1.5 0 0 1 16 5.37v.255a2.375 2.375 0 0 1-4.25 1.458A2.37 2.37 0 0 1 9.875 8 2.37 2.37 0 0 1 8 7.083 2.37 2.37 0 0 1 6.125 8a2.37 2.37 0 0 1-1.875-.917A2.375 2.375 0 0 1 0 5.625V5.37a1.5 1.5 0 0 1 .361-.976zm1.78 4.275a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 1 0 2.75 0V5.37a.5.5 0 0 0-.12-.325L12.27 2H3.73L1.12 5.045A.5.5 0 0 0 1 5.37v.255a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0M1.5 8.5A.5.5 0 0 1 2 9v6h1v-5a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v5h6V9a.5.5 0 0 1 1 0v6h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V9a.5.5 0 0 1 .5-.5M4 15h3v-5H4zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1zm3 0h-2v3h2z"/></svg></Link>
         <p>Shop</p>
    </td>
    
    <td width={'20%'} >
        <Link  to={'https://wa.me/+919654307879?text=Hello%20I%20Need%20Help'} target="_blank" style={{textDecoration:"none"}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bg-success rounded p-1 text-white  bi bi-whatsapp" viewBox="0 0 16 16">  <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232"/></svg>
            <p>Whatsapp</p>
        </Link>
    </td>

    <td width={'20%'}>
        <Link  to={'/HomeRemedies'}  style={{textDecoration:"none"}}>
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height={'30'} fill="currentColor" className="bg-success rounded p-1 text-white  bi-house-add-fill" viewBox="0 0 16 16">
            <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 1 1-1 0v-1h-1a.5.5 0 1 1 0-1h1v-1a.5.5 0 0 1 1 0"/>                                <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L8 2.207l6.646 6.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293z"/>                                <path d="m8 3.293 4.712 4.712A4.5 4.5 0 0 0 8.758 15H3.5A1.5 1.5 0 0 1 2 13.5V9.293z"/>
        </svg>
           
            <p >&nbsp;Remedies</p>
        </Link>
    </td>
    


 {/* <Link to='/' ><svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" fill="currentColor" className="text-success rounded-3 bi bi-person-circle" viewBox="0 0 16 16">  <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>  <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"/></svg></Link> */}
         
    
  </tr>
</tbody>
</table>
 </div>
 <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css" />


          <a href="tel:+918700688655" className="float" target="_blank">
                <i className="fa fa-phone my-float"></i>
          </a>



    </>
  )
}

export default Headres





