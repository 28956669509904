import axios from 'axios'
import React, { useEffect, useState } from 'react'
import ShortContainer from './Shortt/ShortContainer'
import './CSS/ShortVIdeos.css'
const ShortVideos = () => {
    const [allVIdeos, setallVIdeos] = useState([])

   
    return (
    <>

        <div className="container-fluid bg-dark" style={{marginTop:"5rem"}}>


        <ShortContainer/>






            {/* <div className="row"> */}
            {/* <div className="col-12"> */}
            
                    {/* {allVIdeos.map((item,index)=> */}
                        {/* <iframe width="100%" height="315" src={"https://www.youtube.com/embed/"+item.Video} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
                    {/* )} */}
            
                    {/* <iframe width="100%" height="315" src={'https://www.youtube.com/watch?v=eXrwF4LXF5c'} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
            {/* </div> */}
            {/* </div> */}
        </div>


        
    </>
  )
}

export default ShortVideos




