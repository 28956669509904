import styled from "styled-components";

export const StyledTimeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 3px;
  background-color: #ffe3b1;
  border-radius: 10px;
  padding:0.4rem;
  font-weight:bolder
`;
