import React from 'react'
import { Link } from 'react-router-dom'
import './CSS/SugarDiabetes.css'

const HomeRemediesbyDisease = () => {
  return (
    <>
        <div className="container rounded-5" style={{marginTop:"5rem"}}>
            <div className="row rounded-5  ">
                <div className="col-12  rounded-5 mt-3 mb-3" style={{paddingTop:'2rem',alignItems:"center"}}>
                    
                        <div className='p-0  m-0'>
                            <h1 className='btn btn-success w-100 p-0'> घरेलू उपचार <br />(Home Remedies)</h1>
                            <Link to='/HomeRemedies' className='btn btn-success w-25 p-0 fs-1'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25%" fill="currentColor" className="bi bi-house-add-fill" viewBox="0 0 16 16">
                                <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 1 1-1 0v-1h-1a.5.5 0 1 1 0-1h1v-1a.5.5 0 0 1 1 0"/>
                                <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L8 2.207l6.646 6.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293z"/>
                                <path d="m8 3.293 4.712 4.712A4.5 4.5 0 0 0 8.758 15H3.5A1.5 1.5 0 0 1 2 13.5V9.293z"/>
                            </svg>
                            &rArr;
                            </Link>
                            
                        </div>
                    
                </div>
               
            </div>
            <div className="row">
            <div className="col-8 ">
                        <div className="row ">
                            <div className="col-8 ">
                            <Link to={'/SugarDiabetes'} className='tdn mttt'>
                                <div className="text-white homeRmCard rounded-5 mt-4 mb-3 w18rem" >
                                        <img src={'https://i.ibb.co/MDLv7Lw/image.png'} width="100%" height='140px' className="card-img-top rounded-5" alt="" />
                                        <div className='textpedding '>
                                            <i className='text-white' >Sugar Diabetes</i>
                                            <br />
                                            से बचने के लिए
                                            <br />
                                        </div>
                                </div>
                            </Link>
                            </div>
                            
                            
                            
                        </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default HomeRemediesbyDisease
