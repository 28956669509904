//App.js

import React, { useEffect, useState } from 'react';
import './CSS/Product.css';
// import SearchComponent from './ProductOption/SearchComponent';
// import ShowCourseComponent from './ProductOption/ShowCourseComponent';
// import UserCartComponent from './ProductOption/UserCartComponent';
import axios from 'axios';
// import FlipDate from './FlipDate'
import "@pqina/flip/dist/flip.min.css";
import Tick from "@pqina/flip";

import { QRCode } from 'react-qrcode-logo';
import Swal from 'sweetalert2';
import { Link, useNavigate, } from 'react-router-dom';
import Main from './CounterArea/Main';
import EventCountdown from './CounterArea/EventCountDown';
// import { dataHTML } from "./Clock.html";

function Products() {
	// console.log(dataHTML,'dataHTML');
	const [courses, setCourses] = useState([])
// 	const [courses, setCourses] = useState([
// 		{ id: 1, 
// 		name: 'GFG T-shirt', 
// 		price: 499, 
// 		image: 
// 'https://i.ibb.co/7SqV8Sw/gfg3.jpg'
// 		},
// 	]);

	const [CartCourses, setCartCourses] = useState([]);
	const [searchCourse, setSearchCourse] = useState('');
	const [Qtycheck, setQtycheck] = useState(null)
		// console.log(Qtycheck,'Qtycheck---oytside up');
const handleChange=(e)=> {
	setQtycheck(e.target.value)
}

//   console.log(Qtycheck,'Qtycheck');
	console.log(CartCourses,'CartCourses');

const addCourseToCartFunction = (GFGcourse) => {
	
	let addtocartbuttonCss=document.getElementById('changeme')
	addtocartbuttonCss.style.background='blue'
	
	
	
	

	const alreadyCourses = CartCourses
						.find(item => item.product.id === GFGcourse.id);

	let forHideCartButton=GFGcourse.id+'cart'
	// console.log(forHideCartButton,'forHideCartButton');
	// FOr Hidden CArt
	// let CartButton=document.getElementById(forHideCartButton)
	// if (CartButton) {
	// 	CartButton.style.display='none'
	// }
	// FOr Hidden CArt end
	// console.log(forHideCartButton,'forHideCartButton');
	if (alreadyCourses) {
		
			const latestCartUpdate = CartCourses.map(item =>
				
				item.product.id === GFGcourse.id ? {
				...item, quantity: parseInt(item.quantity)+parseInt(1) }
				// ...item, quantity: Qtycheck } 
				: item
			);				
			setCartCourses(latestCartUpdate);
		
		console.log('if in Already');
		
		
		// setCartCourses(latestCartUpdate);
	} 
	else {
		// console.log(Qtycheck,'Qtycheck=-=-=-=ELse');
		if (Qtycheck){
			console.log('ELse in if QtyCheck');
			setCartCourses([...CartCourses, {product: GFGcourse, quantity: Qtycheck }])
		}
		else{
			console.log('ELse in ELse');
			let qtymy=1
			setCartCourses([...CartCourses, {product: GFGcourse, quantity: qtymy }])
		}
		
		

		// setCartCourses([...CartCourses, {product: GFGcourse, quantity: SelectQtyis }]);
		// console.log(CartCourses,'CartCourses-----ELse');
		// if (CartCourses.length==0){
		// 	setCartCourses([...CartCourses, {product: GFGcourse, quantity: parseInt(Qtycheck)}])	
		// }
		

	}
	setTimeout(() => {		addtocartbuttonCss.style.background='green'	}, 500);
};

const deleteCourseFromCartFunction = (GFGCourse) => {
	const updatedCart = CartCourses
						.filter(item => item.product.id !== GFGCourse.id);
	setCartCourses(updatedCart);
};

const totalAmountCalculationFunction = () => {
	return CartCourses
		.reduce((total, item) => 
					total + item.product.Price * item.quantity, 0);
};

const courseSearchUserFunction = (event) => {
	setSearchCourse(event.target.value);
};

const filterCourseFunction = courses.filter((course) =>
	course.Name?.toLowerCase().includes(searchCourse.toLowerCase())
);

useEffect(()=>{
axios.get("https://ayurveda.pythonanywhere.com/ModelApi/v1/ProductsViewSet/")
.then((res)=>{
	console.log(res,'result');
	setCourses(res.data);
	console.log(courses,'courses');
})



window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
},[Qtycheck])

//   const hidemycart=()=>{
// 	let cartHtml=document.getElementById('qrENable')
// 	cartHtml.style.display='block'
// 	let mycart=document.getElementById('mycart')
// 	mycart.style.display='none'
// }
let customerdata='customerdatacustomerdatacustomerdata'
let QRdata='upi://pay?pa=0148000107543894@PUNB0307300.ifsc.npci&pn=Ayurvedic24&am='+totalAmountCalculationFunction()+"&tn="+customerdata+'&cu=INR'
  

const HoverView=(product)=>{
	// console.log('product');	
		Swal.fire({
		  width:"90%",
		  title: product.Name,
		  html: '<div align="left">'+product.Description+'</div>',
		  // imageUrl: "https://placeholder.pics/svg/300x1500",
		  imageUrl: product.Img,
		  // imageWidth: '85%',
		  showCloseButton: true,
	  })
}
const ProductDetail=(product)=>{
	usenavigate('/ProductView/'+product.Name)
}

const hidecart=()=>{	
	let cartlocation=document.getElementById('popup-box')
	cartlocation.style.display='none'
}

const showcart=()=>{	
	let cartlocation=document.getElementById('popup-box')
	cartlocation.style.display='block'
}

const hidemycart=()=>{
	let cartHtml=document.getElementById('qrENable')
	cartHtml.style.display='block'
	let mycart=document.getElementById('mycart')
	mycart.style.display='none'
}

let states = ["Andhra Pradesh","Arunachal Pradesh","Assam","Bihar","Chhattisgarh","Goa","Gujarat","Haryana","Himachal Pradesh","Jammu and Kashmir","Jharkhand","Karnataka","Kerala",   "Madhya Pradesh","Maharashtra","Manipur","Meghalaya","Mizoram","Nagaland","Odisha","Punjab","Rajasthan","Sikkim","Tamil Nadu","Telangana","Tripura","Uttarakhand","Uttar Pradesh","West Bengal","Andaman and Nicobar Islands","Chandigarh","Dadra and Nagar Haveli","Daman and Diu","Delhi","Lakshadweep","Puducherry"]

const [Booking, setBooking] = useState({})
const handler=(e)=>{
	e.preventDefault();
	const {name,value}=e.target; // DISTRUCTIONG
	setBooking({...Booking,[name]:value})
	console.log(Booking,'BOokig');
}

let formmy=document.getElementById("formmy")
const BookingSubmit=(e)=>{
	e.preventDefault();
	console.log(Booking,'Booking');
	if (Booking.PaidCod === 'COD')
	{
		console.log("Thanks For Shopping");
		formmy.style.display='none'

		thnaksShoping.style.display='block'
		setTimeout(() => {
			usenavigate('/Products')
		}, 3000);
		
	}
	else if(Booking.PaidCod === 'OnlinePayment'){
		console.log("Qr payment");
		
			formmy.style.display='none'
		let QROption=document.getElementById('QROption')
			QROption.style.display='block'
	}
	else{	}
}
const usenavigate=useNavigate();
let PaymentDon=document.getElementById("PaymentDon");
let QROption=document.getElementById('QROption');
let thnaksShoping=document.getElementById('thnaksShoping');
const PaymentDone=()=>{
	
		PaymentDon.style.display='none'	
		QROption.style.display='none'	
		thnaksShoping.style.display='block'
		setTimeout(() => {
			usenavigate('/Products')
			hidecart()
			
		}, 3000);
};

{/* Counter Timer code Start */}

useEffect(() => {
	
	console.log();
    const fetchTime = async () => {
      try {
		let location={
			"lat":223,
			"lng":232
		}
		let apiKey='2024-08-09 06:25:32'
        const response = await axios.get("https://worldtimeapi.org/api/timezone/America/Los_Angeles");
		const response2 = await axios.get(`https://maps.googleapis.com/maps/api/timezone/json?location=${location.lat},${location.lng}×tamp=1678387200&key=${apiKey}`);
        console.log(response.data,'-------------------WorldtimeApi------------------');
      }
	  catch (error) {
        console.error("Error fetching time:", error);
      }
    };

    fetchTime();
	
})
{/* Counter Timer code end */}
return (
		<>
		
<div className="" style={{marginTop:"5rem"}}>
			<div className="" onClick={showcart}>
			{/* <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"/> */}
				{/* <a href="#popup-box" className='float btn'  style={{position:'fixed'}}> */}
				<center>
					<a href="#popup-box" className='btn text-white m-auto' id='changeme'  style={{position:"fixed",zIndex:"200",backgroundColor:"green"}}>
						Cart
							&ensp;<img className='rounded-circle p-0' src="/Firefly-Shopping-Cart-Icon-without-background-33552.jpg" width={'40px'}  alt="" />
					</a>
				</center>
          {/* show Button */}
            	
        	</div>


        {/* ?show Area  */}
        <div id="popup-box"  className="modal">
            <div className="content">
                {CartCourses.length===0 ? <>
								<h4 align='center' className='text-white m-auto'>You have not selected any products. <br />
									<img src="https://cdni.iconscout.com/illustration/premium/thumb/empty-cart-7359557-6024626.png?f=webp" width="50%" />
								</h4></> 
                : 
				<>

				{/* UserCartComponent */}
				<div style={{display:'none'}} id='qrENable' >
				<form  id='formmy' method="post" onSubmit={BookingSubmit}>
					Name 
					<input type="text" onChange={handler} name='name' placeholder='Name *' className='form-control' required/><br />

					Mobile no
					<input type="text"  onChange={handler} name='mobileno' placeholder='Mobile no *' className='form-control' required /><br />
					
					Address <br />
					<input type="text"  onChange={handler} name='Address' placeholder='Address *' className='form-control' required /><br />
					
					Landmark (Near By)
					<input type="text"  onChange={handler} name='Landmark' placeholder='Landmark' className='form-control' /><br />
					
					State
					<input className="form-control" name='State'  onChange={handler} list="datalistOptions" id="exampleDataList" placeholder="State *" required />
					<datalist id="datalistOptions">
						{
							states.map((item,index)=>
							<option value={item} key={index}>{item}</option>
							)
						}
					</datalist><br />

					Pin Code 
					<input type="number"  onChange={handler} name='pincode' placeholder='Pin Code *' className='form-control' required /><br />

					<div className="form-check">
						<input className="form-check-input" type="radio" name="PaidCod" value={'COD'}  onChange={handler} />
						<label className="form-check-label" for="PayedCOd">
							COD (Cash On Delivey)
						</label>
					</div>
					<div className="form-check">
						<input className="form-check-input" type="radio" name="PaidCod" value={'OnlinePayment'} onChange={handler} />
						<label className="form-check-label" for="PaidCod">
							Prepaid (Online Payment)
						</label>
					</div>
					
					
					<input type="submit" value="Submit" className='btn btn-primary' />
					<br />
				</form>


				<center><div id='QROption' style={{display:"none"}}>
					<QRCode value={QRdata} />
						<br />
					<button className='btn btn-info mb-2' onClick={PaymentDone} id='PaymentDon'> Submit</button>
					
					<div className='bg-warning rounded-3'>
						<h4>Step1 : Pay Online</h4>
						<h4>Step2 : We will call you within 2 hours</h4>
						<h5>Thanks For Shooping</h5>
						
					</div>
						<br />
				</div></center>

				<center id='thnaksShoping' style={{display:"none"}}>
				<h1>Thanks For Shoppping<br />
				<label htmlFor="" className='text-white'>Please Wait Call Back</label>

				<br />
				<img src="ThanksForShoppping.webp" width={'80%'} alt="" />
				</h1></center>

				</div>

				<div id='mycart' className={`cart ${CartCourses.length > 0 ? 'active' : ''}`} style={{top:'40%'}}>
					<h2>My Cart</h2>
					{CartCourses.length === 0 ? (
					<p className="empty-cart">You have not selected any products.</p>
					) : (


				<div >
					<ul>
					
						{CartCourses.map((item) => (
							
							<li key={item.product.id} className="cart-item">
								<div>
									<div className="item-info">
										<div className="item-image">
											<img src={item.product.Img} 
												alt={item.product.Name} />
										</div>
										<div className="item-details">
											<h3>{item.product.Name}</h3>
											<p>Price: ₹{Number(item.product.Price)}</p>
										</div>
									</div>
									<div>
										<div className="item-actions">
											<button className="remove-button" onClick={() => deleteCourseFromCartFunction(item.product)}>
												Remove Product
											</button>

											<div className="quantity">
												<button style={{ margin: "1%" }} 
												
													onClick={(e) => {
													setCartCourses((prevCartCourses) => {
														const updatedCart = prevCartCourses.map(
														(prevItem) =>
														prevItem.product.id === item.product.id
																? { ...prevItem, quantity: parseInt(item.quantity) + 1 }
																: prevItem
														);
														return updatedCart;
													})
												}}>+</button>
												<p className='quant'>{item.quantity} </p>
												<button 
													onClick={(e) => {
													setCartCourses((prevCartCourses) => {
														const updatedCart = prevCartCourses.map(
														(prevItem) =>
														prevItem.product.id === item.product.id
																? { ...prevItem, quantity:
																Math.max(item.quantity - 1, 0) }
																: prevItem
														);
														return updatedCart;
													})
												}}>-</button>
											</div>
										</div>
									</div>
								</div>
							</li>
						))}
					</ul>
					<div className="checkout-section " >
						<div className="checkout-total ">
							<p className="total">Total Amount: 
								₹{totalAmountCalculationFunction()}
							</p>
						</div>
						{/* <button className="checkout-button" onClick={hidemycart} */}
						<button className="checkout-button" onClick={hidemycart}>
							
							Proceed to Payment
						</button>
					</div>


				</div>

						)}
				</div>
				{/* UserCartComponent end */}

                	{/* <UserCartComponent
                    CartCourses={CartCourses}
                    deleteCourseFromCartFunction={deleteCourseFromCartFunction}
                    totalAmountCalculationFunction={totalAmountCalculationFunction}
                    setCartCourses={setCartCourses}/>  */}

				</>
					}
                  
				  
					<Link  style={{position: 'absolute',top:' 10px',
                        right: '10px',color:' #fe0606',
                        fontSize: '30px',textDecoration: 'none'}} onClick={hidecart}>
                    &times;
                </Link>
				{/* cart hide Refresh */}
                {/* <a href="" style={{position: 'absolute',top:' 10px',
                        right: '10px',color:' #fe0606',
                        fontSize: '30px',textDecoration: 'none'}} onClick={hidecart}>
                    &times;
                </a> */}
						{/* cart hide Refresh end*/}
            </div>
        </div>
        {/* ?show Area End */}
    {/* SearchComponent */}
	<br/>
	<header className="">
			<div className="search-bar border">
				<input
					type="text"
					placeholder="Search Products..."
					value={searchCourse}
					onChange={courseSearchUserFunction}
				/>
			</div>
		</header>
	{/* SearchComponent End */}
				{/* <SearchComponent searchCourse={searchCourse} courseSearchUserFunction= {courseSearchUserFunction} /> */}
			
			<main className="App-main ">
			{/* ShowCourseComponent */}

{/* Counter Timer Start */}

<div className='pb-3 w-100'><Main/></div>




{/* Counter Timer End */}

		<div className="container ">
		<div  className="row " >

			{filterCourseFunction.length === 0 ? (
				<p className="no-results">

				Please Wait.
				
				</p>
			) : 
			(
				filterCourseFunction.map((product) => (
					<div className="col-md-2 col-5 productaaa ms-4" style={{borderBottomLeftRadius:"0",borderTopRightRadius:"0"}}>
						<center>
							<div className="row -0 w-100" key={product.id}>
							<div className="col-md-12 product mt-1"  >
									<img src={product.Img} alt={product.Name} onClick={()=>ProductDetail(product)} className='w-100 mt-1' style={{height:"150px"}} />	
									<span onClick={()=>HoverView(product)} className='rounded-circle i-buttons'>
											<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" style={{color:'orangered'}} className=" bi bi-info-circle-fill" viewBox="0 0 16 16"><path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2"/></svg>
									</span>
							</div>
							<div className="mt-2 text-center p-0" >
									{  product.id+"cart" ? (
											<>
												<button className=" text-white add-to-cart-button p-2 " 
												id={product.id+"cart"} onClick={() => addCourseToCartFunction(product)} 
												style={{backgroundColor:'orangered',width:"91%"}}>
														Add to Cart
												</button>	
											</>
										)
										:
										(<>
											<p><i>Added</i></p>
										</>)
									}
										
							</div>
							<div className="col-md-12 text-center  p-0 m-0" onClick={()=>ProductDetail(product)}><h6>{product.Name}</h6></div>
							<div className="col-md-12 text-center p-0 m-0" onClick={()=>ProductDetail(product)}>Price: ₹{Number(product.Price)}</div>

							<select className='bg-white form-select mb-2 m-auto' name="mlQty" onChange={(e)=>handleChange(e)} id="branch" style={{width:"90%"}}	>
								
								<option align='center' value={1} className='bg-white text-dark '>Pack of 1 </option>
								<option align='center' value={2} className='bg-white '>Pack of 2</option>
								<option align='center' value={3} className='bg-white '>Pack of 3</option>
								<option align='center' value={4} className='bg-white '>Pack of 4</option>
							</select>
							
						</div>

						
						</center>
					</div>
				))
			)}

		</div>
		</div>
			{/* ShowCourseComponent End */}
				{/* <ShowCourseComponent courses={courses} filterCourseFunction={filterCourseFunction} addCourseToCartFunction={addCourseToCartFunction} /> */}

</main>
	
</div>



		</>
	);
}

export default Products;















