import React from 'react'
import { Link } from 'react-router-dom'

const SellerCentral = () => {
  return (
    <>
    <div style={{marginTop:"5rem"}}></div>
        <div className="row">
            <div className="col-3 border bg-dark text-white">
                <h1>Menu</h1>
                <Link to={''} className='btn text-white ' >Home</Link><br />
                <Link to={''} className='btn text-white mb-2 w-100'>Order</Link><br />
                <Link to={''} className='btn text-white mb-2 w-100'>Return</Link><br />
                <Link to={''} className='btn text-white mb-2 w-100'>Payment</Link><br />
                <Link to={''} className='btn text-white mb-2 w-100'>Catalog Upload</Link><br />
                <Link to={''} className='btn text-white mb-2 w-100'>Reports</Link><br />
            </div>

            <div className="col-9">
                <h1>Dashboard</h1>
            </div>
        </div>
    </>
  )
}

export default SellerCentral