
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import Headres from './Pages/Headres';

import Products from './Pages/Products';
import CustomerCare from './Pages/CustomerCare'

import Blog from './Pages/Blog'
import Pagenotfound from './Pages/Pagenotfound';
import ShortVideos from './Pages/ShortVideos';
import { useEffect, useRef } from 'react';
import axios from 'axios';
import Footer from './Pages/Footer';

import emailjs from '@emailjs/browser';
// import CartSlice from './Pages/Produ/CartSlice';
// import ShoppingCart from './Pages/Produ/ShoppingCart';
import BlogExplorer from './Pages/BlogExplorer';
import HomeRemedies from './Pages/HomeRemedies';
import HomeRemediesViews from './Pages/HomeRemediesViews';
import HomeRemediesbyDisease from './Pages/HomeRemediesbyDisease';
import SugarDiabetes from './Pages/SugarDiabetes';
import GoogleLanding from './Pages/GoogleLanding';
import ProductView from './Pages/ProductView';
import Buyarea from './Pages/Buyarea';
import ThanksShopping from './Pages/ThanksShopping/ThanksShopping';

import data from './data/data.json'  
import StorePolicy from './Pages/Footer_Options/StorePolicies';
import PrivacyPolicy from './Pages/Footer_Options/PrivacyPolicy';
import ReturnPolicy from './Pages/Footer_Options/ReturnPolicy';
// import Counter from './Pages/Counter';
import Main from './Pages/CounterArea/Main';
import Swal from 'sweetalert2';
import SaleStart from './Pages/SaleStart';


import SellerCentral from './Pages/Admins/SellerCentral'
// console.log(data,'-------=-=sss=-=-=-------------');
function App() {



//   axios.get(data)
//  .catch((err) => {
//     console.log(err.config.url);
//   });
// console.log(window,'window');
  let checkddddd={
    "username": "Khaby Leme",
    "videoUrl": "https://github.com/hicodersofficial/images/blob/main/khaby1.mp4?raw=true",
    "isFollowed": false,
    "title": "Bro Jasonderulo it’s not my fault 🥹 please forgive me 🙏🏿🫶🏿 #shorts #khabylame #funny",
    "profileUrl": "https://yt3.ggpht.com/-ieEoBkfKwx42qXBFbJy1L4BgBXMUOum85q9XJQMBzi50gWI8HRTT_ADsKdyufECc78DtMnG-Q=s176-c-k-c0x00ffffff-no-rj",
    "reaction": {
        "likes": "100K",
        "comments": "10K",
        "isLiked": false
      }
    }





  // function Pop() {
  //   const form = document.getElementById("thanks"); 
  //   const successMessage =document.getElementById("congo");

  //   setTimeout(() => {
  //     form.addEventListener("submit", function (e) { 
  //         e.preventDefault();
  //         successMessage.style.display = "block"; 

  //         let mainSite=document.getElementById('website')
  //         mainSite.style.display='none'

  //   });
      
  //   }, 4000);
    
  // }
// FOrm Data Save--------------------------
  let Patientname =useRef();
  let MobileNo=useRef();
  let Disease=useRef();
  let City=useRef();
  
  // Emailjs--------------------  
  const form=useRef();
  // Emailjs----end----------------  

  function saveFormData(e) {
    e.preventDefault();
      // Emailjs----end----------------  
      emailjs.sendForm('service_mr3c2me', 'template_rrvniru', form.current, 'aPTr3AhgArj2jD9hg')
        .then((res) => {
            // console.log(res.text);
        }, (error) => {
            // console.log(error.text);
        });
    
    // Emailjs----end----------------
    
    let allformdata={
      Patientname:"Patient Name: "+Patientname.current.value+"   "+" City : "+City.current.value,
      Mobileno:String(MobileNo.current.value),
      Disease:Disease.current.value,

    }
    console.log(allformdata,'allformdata');
    axios.post('https://ayurveda.pythonanywhere.com/ModelApi/v1/FeedbackFormViewSet/',allformdata)
    .then((res)=>{
      
        // console.log(res,'Success Fully Saved');
        Swal.fire({
          icon:"success",
          html: `<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-phone-vibrate" viewBox="0 0 16 16">  <path d="M10 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zM6 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2z"/>  <path d="M8 12a1 1 0 1 0 0-2 1 1 0 0 0 0 2M1.599 4.058a.5.5 0 0 1 .208.676A7 7 0 0 0 1 8c0 1.18.292 2.292.807 3.266a.5.5 0 0 1-.884.468A8 8 0 0 1 0 8c0-1.347.334-2.619.923-3.734a.5.5 0 0 1 .676-.208m12.802 0a.5.5 0 0 1 .676.208A8 8 0 0 1 16 8a8 8 0 0 1-.923 3.734.5.5 0 0 1-.884-.468A7 7 0 0 0 15 8c0-1.18-.292-2.292-.807-3.266a.5.5 0 0 1 .208-.676M3.057 5.534a.5.5 0 0 1 .284.648A5 5 0 0 0 3 8c0 .642.12 1.255.34 1.818a.5.5 0 1 1-.93.364A6 6 0 0 1 2 8c0-.769.145-1.505.41-2.182a.5.5 0 0 1 .647-.284m9.886 0a.5.5 0 0 1 .648.284C13.855 6.495 14 7.231 14 8s-.145 1.505-.41 2.182a.5.5 0 0 1-.93-.364C12.88 9.255 13 8.642 13 8s-.12-1.255-.34-1.818a.5.5 0 0 1 .283-.648"/></svg>
           <h3>धन्यवाद, हम आपसे जल्द ही संपर्क करेंगे</h3>
      <h3>Thank you, for your appointment  we will contact you soon</h3>`,
          
          // text: "You clicked the button!",
        })
        popclose()

    })
    .catch((error)=>{
        // console.log(error,'--------error-----------');
    })
  }

// FOrm Data Save------end--------------------
  function popclose() {
    
    let checkbutton=document.getElementById('modelsss')
    // console.log(checkbutton,'checkbutton');
    checkbutton.style.display='none'
    let mainSite=document.getElementById('overlay')
    mainSite.style.display='none'

  }

  function Feedbacktimer() {

    let checkbutton=document.getElementById('modelsss')
    let mainSite=document.getElementById('overlay')
    mainSite.style.display='none'
    setTimeout(() => {
      // checkbutton.style.display='block'
      // mainSite.style.display='block'
      mainSite.style.display='block'
      checkbutton.style.display='block'
      
    }, 7000);
    
    // console.log(a);
    
  }

// --------------------------------------------------
// ---------------------------------------------------


  return (
    <>
    <div className="App "  >
     
    {/* {Object.keys(data).map((short,index) => (
      <></>
        ))} */}
{/* POPUP1 */}

    <BrowserRouter>
        <div onLoad={Feedbacktimer} >
        <Headres />
        </div>
          <Routes >
          
              <Route path='/' element={<Home/>} />
              
              <Route path='Products' element={<Products/>}/>
              <Route path='CustomerCare' element={<CustomerCare/>}/>
              
              <Route path='Blog' element={<Blog/>}/>
              
              <Route path='ShortVideos' element={<ShortVideos/>}/>
              <Route path='BlogExplorer/:id' element={<BlogExplorer/>}/>

              {/* <Route path='CartSlice' element={<CartSlice/>}/> */}
              {/* <Route path='ShoppingCart' element={<ShoppingCart/>}/> */}

              <Route path='HomeRemedies' element={<HomeRemedies/>}/>
              <Route path='HomeRemediesViews/:id' element={<HomeRemediesViews/>}/>
              <Route path='HomeRemediesbyDisease' element={<HomeRemediesbyDisease/>}/>
              <Route path='SugarDiabetes' element={<SugarDiabetes/>}/>
              <Route path='GoogleLanding/:id' element={<GoogleLanding/>}/>
              <Route path='ProductView/:id' element={<ProductView/>}/>
              <Route path='Buyarea/:id' element={<Buyarea/>}/>

              <Route path='ThanksShopping/:id' element={< ThanksShopping/>}/>
          
              <Route path='StorePolicy' element={<StorePolicy/>}/>
              <Route path='PrivacyPolicy' element={<PrivacyPolicy/>}/>
              <Route path='ReturnPolicy' element={<ReturnPolicy/>}/>
              {/* <Route path='Counter' element={<Counter/>}/> */}
              <Route path='Main' element={<Main/>}/>
              <Route path='SaleStart' element={<SaleStart/>}/>

              <Route path='SellerCentral' element={<SellerCentral/>}/>

              

              <Route path='*' element={<Pagenotfound/>}/>
          </Routes>
          <Footer/>
    </BrowserRouter>
    
    
<div  className="model active rounded-2 rounded"  id="modelsss"  style={{display:"none"}}>
        <div className="model-header align-it bg-info" >
        <label htmlFor="" className="title p-0 m-0 "  style={{fontSize:"32px"}} >
            <label htmlFor="" style={{fontWeight:"bolder" ,fontSize:"43px",paddingTop:"-10%"}} >FREE</label> Consultation
        </label>

        <button id="closebbutton" onClick={popclose} className="close-button rounded-circle"><h1>&times;</h1></button>
        </div>
        <div className="model-body">
                <p className='m-0'><i>
        
                </i></p>

                <form id="thanks" onSubmit={saveFormData} ref={form} netlify >
                    <input type="text" name="Patientname" ref={Patientname}  placeholder="Patient name" className='form-control w-75 p-0' required /><br />  
                    <input type="number"  name="MobileNo" ref={MobileNo}  placeholder="Whatsapp / Mobile No" className='form-control w-75 p-0' required />  <br />
                    <input type="text" name="City" ref={City}  placeholder="City Name" className='form-control w-75 p-0' required />  <br />
                    <input type="text" name="Disease" ref={Disease}  placeholder="Disease" className='form-control w-75 p-0' required />  <br />
                    <input type="submit" value="Book"  className='btn btn-success w-50'/>
                </form>

                <p className="msg" id="congo"> 
                    Thanks for Subscribing.
                </p> 
        </div>
  </div>

    <div className="active" id="overlay" style={{display:"none"}}></div>
    </div>


      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
 
    </>

  );
}

export default App;
