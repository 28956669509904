import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

const GoogleLanding = () => {
  const [DataView, setDataView] = useState({})
  let useparams=useParams()
  console.log(useparams.id,'');
  
  useEffect(() => {

  }, [])
  
  return (
    <>
    <div style={{marginTop:'8rem'}}>
    

    </div>

    </>
  )
}

export default GoogleLanding
