import React from 'react'
import { Link } from 'react-router-dom'
import './CSS/Fotter.css'
const Footer = () => {
  return (
    <>
        <hr /><hr /><hr />  
  <div className="container-fluid mt-1">
       
                <div className='text-center mb-2'><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"  viewBox="0 0 16 16">  <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0"/>  <path d="M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v6.5a.5.5 0 0 1-1 0V1H3v14h3v-2.5a.5.5 0 0 1 .5-.5H8v4H3a1 1 0 0 1-1-1z"/>  <path d="M4.5 2a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm-6 3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm-6 3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5z"/></svg>
                      Ayurvedic24
                </div>
          
        <h5 className='bg-primary text-white pb-1'>Working Area</h5>
        <h6 className='bg-info p-1 text-white'>Services Time 9:00am  - 9:00pm</h6>
        <div className="row">
        
          <div className="col-md-6">
              <h6 style={{color:'blue'}}>Ayurvedic24 1733,  Ground Floor, Rod Gran Lal Kuan Near - Chawri Bazar Metro Station & Chandni Chowk Delhi - <strong>INDIA</strong> - 110006 <br />Talk to Us : <a href="tel:+918700688655" className='tdn'>+91 8700-688-655</a></h6>
          </div>
          <div className="col-md-6">
              <h6 style={{color:'blue'}}>Ayurvedic24 152 Moti Bagh, 3 Floor,  Gulati Niwas, Shiv Mandir Ring Road Satya Niketan Near Durga Bhai Deshmukh Metro Station Pink Line New Delhi - <strong>INDIA</strong> - 110021<br />Talk to Us : <a href="tel:+918700688655" className='tdn'>+91 8700-688-655</a></h6>
          </div>
        
        </div>
        

        
  </div>
  <hr />
  <h3>
      <a href="https://x.com/ayurvedic24">
        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" className="ms-3 bi bi-twitter-x text-dark" viewBox="0 0 16 16"> <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/></svg>
      </a>


      <a href="https://www.facebook.com/share/ERXXuhsEPHx5eupt/?mibextid=qi2Omg">
      <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" className="ms-3 bi bi-facebook " viewBox="0 0 16 16">
        <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"/>
      </svg>
      </a>

      <a href="https://whatsapp.com/channel/0029VaiPmGVFXUueYELpRk02">
      <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" className="ms-3 bi bi-whatsapp text-success  " viewBox="0 0 16 16">
        <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232"/>
      </svg> 
      </a>


      <a href="https://www.instagram.com/ayurvedic24.in">
      <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" className="ms-3 bi bi-instagram text-danger" viewBox="0 0 16 16">
        <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334"/>
      </svg>
      </a>

      <a href="https://youtube.com/@ayurvedic24_india">
      <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" fill="currentColor" className="ms-3 bi bi-youtube text-danger" viewBox="0 0 16 16">
        <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z"/>
      </svg>
      </a>
      <br />
              &ensp; &#x2B9E; Ayurvedic 24
  </h3>

  
 <div className="container-fluid mt-4 " >
  <div className="row">
    <div className="col-md-3 text-start" >
      <h4 className='btn btn-success w-100 rounded-0 text-start'>About <br /></h4>
              <div className='fotterMobilecenter mb-2 '><a href='/StorePolicy' className='text-decoration-none ' style={{marginBottom:"1rem"}}>Terms And Condition</a></div>
              <div className='fotterMobilecenter mb-2 '><a href='/PrivacyPolicy' className='text-decoration-none ' style={{marginBottom:"1rem"}}>Privacy Policy</a></div>
              <div className='fotterMobilecenter mb-2 '><a href='/ReturnPolicy' className='text-decoration-none ' style={{marginBottom:"1rem"}}> Return Policy</a></div>
            
    </div>

    <div className="col-md-3 text-start" >
    <h4 className='btn btn-success w-100 rounded-0 text-start'>SHOP <br /></h4>
            <div className='fotterMobilecenter mb-2 '> <a href='/Products' className='text-decoration-none ' style={{marginBottom:"1rem"}}>Shop</a></div>
            <div className='fotterMobilecenter mb-2'> <a href='./CustomerCare' className='text-decoration-none ' style={{marginBottom:"1rem"}}>Contact Us</a></div>
    </div>

    <div className="col-md-3 text-start" >
    <h4 className='btn btn-success w-100 rounded-0 text-start'>QUICK LINKS <br /></h4>

            
            <div className='fotterMobilecenter mb-2'> <a href='/Blog' className='text-decoration-none ' style={{marginBottom:"1rem"}}>Blogs</a></div>
            <div className='fotterMobilecenter mb-2'> <a href='/ShortVideos' className='text-decoration-none ' style={{marginBottom:"1rem"}}>Videos Cares</a></div>
            <div className='fotterMobilecenter mb-2'> <a href='' className='text-decoration-none ' style={{marginBottom:"1rem"}}>Services</a></div>
            <div className='fotterMobilecenter mb-2'> <a href='' className='text-decoration-none ' style={{marginBottom:"1rem"}}>About Us</a></div>

             

    </div>

    <div className="col-md-3 text-start" >
      <h4 className='btn btn-success w-100 rounded-0 text-start'>COMPLIANTS / GREVIANCES <br /></h4>

              <div className='fotterMobilecenter mb-2'><a href='' className='text-decoration-none ' style={{marginBottom:"1rem"}}>Employee Grievance</a></div>
              <div className='fotterMobilecenter mb-2'><a href='' className='text-decoration-none ' style={{marginBottom:"1rem"}}>Customer Grievance</a></div>
              <div className='fotterMobilecenter mb-2'><a href='' className='text-decoration-none ' style={{marginBottom:"1rem"}}>Posh Complaints</a></div>
              <div className='fotterMobilecenter mb-2'><a href='' className='text-decoration-none ' style={{marginBottom:"1rem"}}>Whistle Blower</a></div>
    </div>

  </div>



  
 
</div>
<br /><br /><br />

    </>
  )
}

export default Footer
