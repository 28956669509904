import axios from 'axios'
import React, { useEffect, useState } from 'react'
import HomeRemediesbyDisease from './HomeRemediesbyDisease'
import { Link } from 'react-router-dom'
import './CSS/SugarDiabetes.css'
import { useSpeechSynthesis } from 'react-speech-kit';
const SugarDiabetes = () => {
    const [sUgardata, setsUgardata] = useState([])
    const [alldata, setalldata] = useState(
        [
            {
                "id": 1,
                "DiseaseName": "Diabetes",
                "Ingredientnames": "मेथी (Fenugreek)",
                "ItemPhoto": "https://i.ibb.co/Z1jDHW1/72d321ba-16db-46eb-aac7-6be3daccb73e.jpg",
                "HowToUse": "मेथी - वैसे तो मेथी का स्वाद कड़वा होता है लेकिन यह शुगर, ओबेसिटी और कोलेस्ट्रॉल के लिए सबसे अच्छी आयुर्वेदिक उपाय है। शुगर कंट्रोल करने के लिए मेथी का सेवन करना बहुत अच्छा उपाय होता है। इसके लिए एक चम्मच मेथी पाउडर खाली पेट या सोते समय गुनगुने पानी के साथ सेवन कर सकते हैं। इसके अलावा आप एक चम्मच मेथी के दाने को पानी में भीगो कर रात भर के लिए छोड़ दे और सुबह खाली पेट इसका सेवन कर सकते हैं।"
            },
            {
                "id": 2,
                "DiseaseName": "Sugar ",
                "Ingredientnames": "काली मिर्च (Black pepper)",
                "ItemPhoto": "https://i.ibb.co/SKzgqFV/c985f28a-14b1-471a-bd64-396fc5c233d0.jpg",
                "HowToUse": "काली मिर्च - काली मिर्च शुगर लेवल कंट्रोल करने का बेहतरीन आयुर्वेदिक इलाज है। इसमें पिपेरिन नामक कंपोनेंट होता है। इसके लिए आप एक चम्मच काली मिर्च के पाउडर को हल्दी के साथ मिक्स करें और रात को खाने से एक घंटे पहले सेवन करें।"
            },
            {
                "id": 3,
                "DiseaseName": "Diabetes",
                "Ingredientnames": "दालचीनी (cinnamon)",
                "ItemPhoto": "https://i.ibb.co/ZTyySkL/13ca5a1c-0f6a-43df-bd44-74852c99969e.jpg",
                "HowToUse": "दालचीनी - दालचीनी भी डायबीटीज को कम करने का काम करती है। यह इंसुलिन रेजिस्टेंस कम करने के साथ कोलेस्ट्रोल और वसा को भी कम करती है। इसके लिए एक चम्मच दालचीनी, आधा चम्मच मेथी पाउडर और हल्दी मिक्स करें और खाली पेट आप इसका सेवन कर सकते है। आप इसका हर्बल टी भी पी सकते हैं, चाय में दालचीनी का टुकड़ा जरूर मिलाएं।"
            },
            {
                "id": 4,
                "DiseaseName": "Sugar ",
                "Ingredientnames": "नीम (neem)",
                "ItemPhoto": "https://i.ibb.co/kxhTbX1/ffdb95d7-c46f-48ab-8178-d5995d7c91a3.jpg",
                "HowToUse": "नीम\r\nशुगर पेशेंट के लिए नीम की पत्तियां बहुत फायदेमंद होती है। यह ब्लड शुगर लेवल को कंट्रोल करने में मदद करती है। आप नीम की पत्तियों को सुखा लें, उसके बाद पीस कर चिकना कर लें, दिन में दो बार आप इसके चूर्ण को ले सकते हैं।"
            },
            {
                "id": 5,
                "DiseaseName": "Diabetes",
                "Ingredientnames": "करेला (Bitter gourd)",
                "ItemPhoto": "https://i.ibb.co/0yjWqhr/a3193b16-70fd-4c9f-a571-cb4a938c0e34.jpg",
                "HowToUse": "करेला आपके शुगर लेवल को कंट्रोल रखता है। यदि आप नियमित रूप से करेला का जूस पीते हैं या सब्जी खाते हैं, तो आप डायबिटीज जैसी खतरनाक बीमारी से बच सकते हैं।"
            },
            {
                "id": 6,
                "DiseaseName": "Sugar",
                "Ingredientnames": "जामुन (Jamun)",
                "ItemPhoto": "https://i.ibb.co/pdQkBn7/28138a20-e024-408b-85bd-318928c62724.jpg",
                "HowToUse": "जामुन\r\nडायबिटीज पेशेंट के लिए जामुन के कई फायदे हैं। आप जामुन का सेवन काला नमक के साथ कर सकते हैंं। इसके अलावा आप जामुन की गुठली को सुखा कर, पीसने के बाद, उसके चूर्ण का सेवन कर सकते हैं। आप दो चम्मच इसके चूर्ण को गुनगुने पानी में डाल कर, सुबह और शाम के समय पी सकते हैं।"
            }
        ]
    )
    const [SearchProduct, setSearchProduct] = useState('Sugar')
    const LoadData=()=>{
        // axios.get("https://ayurveda.pythonanywhere.com/ModelApi/v1/MyUpcharViewSet/")
        // .then((res)=>{
        //     setalldata(res.data)
        //   console.log(alldata);
        // })
        // .catch((err)=>{
        //   console.log(err,'MY ERROR');
        // })
      }
    
      useEffect(() => {
        LoadData()
        // console.log(alldata,'alldata');
        
        // console.log(HomeRemediesDatas,'HomeRemediesDatas')
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
      }, [])


    //   const filterCourseFunction = alldata.filter((item) =>
	// 	item.DiseaseName?.toLowerCase().includes(SearchProduct.toLowerCase())
	// );
    const [found, setfound] = useState([])
    const courseSearchUserFunction = (event) => {

		setSearchProduct(event.target.value);
        console.log(event.target.value,'event.target.value');
        console.log(SearchProduct,'SearchProduct');

        let finddata=alldata.filter(data => data.DiseaseName==='Sugar');
        
        console.log(finddata,'finddata');
	};
    
  
  
    //   text to Speach
    const { speak,voices} = useSpeechSynthesis();
    console.log(voices,'voices');
    let selectedVoice = voices.find(voice => voice.lang.includes('hi-IN'));
    
    

    //   text to Speach end
  return (
    <>
      {/* <input type="text" value={SearchProduct} onChange={courseSearchUserFunction} /> */}

      <br /><br />
    <div className="container" style={{marginTop:"5rem"}}>
        <div className="row">
            <div className="col">
                <Link to='/HomeRemediesbyDisease' className='seven'>
                <h1 className=''>
                    Sugar Diabetes
                </h1>
                </Link>
            </div>
        </div>
    </div>

    <div className="container mt-4">
        <h1>उपाय (Remedy)</h1>
        <div className="row">
                    {alldata.map((item,index)=>
                        <div className="card m-3" style={{width:'18rem'}} key={index}>
                            <img src={item.ItemPhoto} className="card-img-top" alt="..." />
                            <div className="card-body">
                                <h5 className="card-title">{item.Ingredientnames}</h5>
                                <p className="card-text">{item.HowToUse}</p>
                                <button className='btn btn-success' onClick={() => speak({text: item.HowToUse, voice: selectedVoice ? voices[166] : voices[10] })}>Listen</button>
                            </div>
                        </div>
                        
                    )}
                
            </div>
    </div>
    </>
  )
}

export default SugarDiabetes

