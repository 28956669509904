import React from 'react'

const PrivacyPolicy = () => {
  return (
    <>
      <div className="container">
      <div class="css-1c02o5u" style={{marginTop:'5rem'}}>
        <p class="h2" mdn-text="">Privacy Policy</p>
        <p class="css-1yp3xav text-start" mdn-text="">
        <div>This Privacy Policy ("Privacy Policy") explains how we use your information when you access or use our storefront, its corresponding mobile site or mobile application (collectively, "Storefront"), and/or purchase products, and/or utilize any services or other offerings made available through the Storefront (collectively, "Storefront Features").
        <br />
This Privacy Policy does not apply to any information that you provide to, or that is collected by, any third-party.Please consult directly with such third-party about its information security practices, policies, and procedures.
<br />
<br />
<h3>User acceptance</h3>
<br />
By accessing or using the Storefront, you agree to the terms of this Privacy Policy for collection, use, storage, disclosure and otherwise processing of your personal information (including sensitive personal data or information) in accordance with the terms and conditions of this Privacy Policy and as permitted under applicable laws, including but not limited to the Information Technology Act, 2000 and the rules framed thereunder ("IT Laws").
Any information provided by you and/or collected by us, which is subject matter of this Privacy Policy, is collected, retained, or otherwise processed by [Amazon SmartCommerce Solutions Pvt. Ltd.] (having its registered office at H-9, Block B-1, Mohan Cooperative Industrial Area, Mathura Road, New Delhi -110044 ("Amazon") as a data processor for and on our behalf (having our office at the location mentioned in contact us section).
<br />
If you do not agree with the practices described in this Privacy Policy, please do not provide us with your personal information, or use, access or interact with the Storefront. You may withdraw your consent to processing of your information collected and/or held by us under this Privacy Policy. If you withdraw your consent for processing of your information collected and/or held by us, we may be unable to provide you access to the Storefront or any of the Storefront Features.
<br />
If you disclose to us any personal information relating to other people, you represent that you have the authority to do so and to permit us to use such information in accordance with this Privacy Policy.
<br />
<br />
<h3>Collection of your information</h3>
<br />
1.We may collect certain personal information from you, such as your name, email address, phone number, etc. to enable you to access to the Storefront and all Storefront Features. You must ensure that you update your personal information and ensure that such information remains accurate and up-to-date at all times. If you choose not to provide us your personal information, you may not be able to access the Storefront or take benefit of our Storefront Features.
<br />
2.We may also collect certain other information related to the electronic device you use to access the Storefront such as IP address, browser type, operating system, device type, or information that you may electronically submit when you post on message boards, or information contained in searches, reviews, ratings or comments that you submit or information about you from your proxy or from other persons or sources, or the details of your visits, or other site usage behavior. Such information may be used by us to administer the Storefront and provide general statistics regarding the use of Storefront. We may also use this information to share important updates and information through email &amp; SMS, and also prevent communication that might not interest you.
<br />
3.We may also collect your personal information via other sources such as updated delivery address or contact information from our carrier partners, information from our payment service providers, public records, publicly available sources or internet sites, vendors, data suppliers and service providers, commercially available marketing lists or registries, telephone directories, social network, news outlets and related media.
<br />
<br />
<h3>Use of your information</h3>
<br />

We may use your information or data that we collect for the following purposes:-
<br />
1.To enable you to access the Storefront and/or avail the Storefront Features;
<br />
2.To handle your orders - deliver products and services, or communicate with you about your orders, any new products and services, or promotional offers or in connection with the Storefront and Storefront Features via different channels (e.g., by phone, e-mail);
<br />
3.To provide functionality, analyze performance, fix errors, and improve the usability and effectiveness of the Storefront and Storefront Features;
<br />
4.To recommend products or promotional offers to you that might be of interest to you, identify your preferences. We may also use your preferences or details regarding your usage of the Storefront and Storefront Features for marketing and other promotional activities;
<br />
5.To comply with applicable law or requests received from regulators, government or judicial authorities;
<br />
6.To prevent and detect fraud and abuse;
<br />
7.To carry out our obligations and enforcing rights arising from any contracts entered into between us;
<br />
8.To help improve the quality and design of our Storefront and/ or Storefront Features and to create new features, promotions, functionality and services; and/or
<br />
9.To fulfil any other purpose for which you provide us the information and/or for any other purpose with your consent.
<br />
<br />
<h3>Sharing of your information</h3> 
<br />
We may share your information for the following purposes:-
<br />
1.With vendors or third party service providers that we use for a variety of purposes in connection with the Storefront or Storefront Features, such as to send you communications via emails, messages or tele-call to inform you about the services and/or products that may be of interest to you, push notifications to your electronic device on our behalf, collect and process payments, and deliver your orders. We may also use other third parties for other projects, such as conducting surveys for us, and may share your information with such third parties;
<br />
2.With our holding companies, subsidiaries, and affiliates, which operate under common ownership or control;
<br />
3.With a buyer or other successor in the event of a merger, restructuring, reorganization, dissolution or other sale or transfer of some or all the assets, whether as a going concern or as part of bankruptcy, liquidation or similar proceeding, in which your information may be amongst the assets transferred;
<br />
4.With law enforcement agencies, courts, regulators and/or other governmental organizations to ensure compliance with orders or government directives that have jurisdiction;
<br />
5.To enforce or apply the Customer Terms of Use and other agreements; and/or
<br />
6.In any other circumstances where we have your consent for any other purpose disclosed by us when you provide the information.
<br /><br />
<h3>Third party policies </h3>
<br />
We may work with different third parties in connection with the Storefront and Storefront Features. Such third parties may have different privacy policies and security practices than we have. Please be aware that any information provided to any third-party or when you click through to third-party links while using the Storefront, our privacy policies no longer apply and that we are not responsible for the privacy practices of these third parties. We encourage you to review the policies of each third-party to whom you provide personal or other information.
<br /><br />
<h3>Our security practices and policies</h3>
<br />
1.We implement reasonable security practices and procedures as mandated under applicable laws including but not limited to the IT Laws and have comprehensive documented information security programme and information security policies that contain managerial, technical, operational and physical security control measures that are commensurate with respect to the information being collected and the nature of our business. 
<br />
2.We protect the security of your information during transmission by using servers that have Firewall and Secure Sockets Layer (SSL) certificate installed, which encrypts information you input and prevents unauthorized access of such information to or from a private network in addition to maintaining security of your information as per the International Standard IS/ISO/IEC 27001 on "Information Technology Security Techniques Information Security Management System-Requirements".
Modifications
<br />
We reserve the right to change, modify, insert, or delete any provision of this Privacy Policy, from time to time, in our sole discretion, without any prior notice to you.
We encourage you to periodically review this page for the latest information on our privacy practices. Your use of the Storefront or Storefront Features after an updated privacy policy becomes effective will indicate your acceptance of the updated policy.
<br /><br />
<h3>Contact us</h3>

If you would like to ask about, make a request relating to, or complain about how we process your information, please contact us at with the details mentioned in the "reach out to Us" Section
</div>

</p></div>
      </div>
    </>
  )
}

export default PrivacyPolicy