import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Short from "../Shortt/Short";


function ShortContainer() {
  const shortContainerRef = useRef();
  const [allVIdeos, setallVIdeos] = useState([])
  const [data, setdata] = useState([])

  function checkVodeoData() {
        
    axios.get('https://ayurveda.pythonanywhere.com/ModelApi/v1/VideoViewSet/')
    .then((res)=>{
        console.log(res.data);
        setdata(res.data.reverse())
    })
    .catch((error)=>{
        console.log(error,'--------error-----------');
    })
  }
    useEffect(() => {
      checkVodeoData()
    }, [])
      

  return (
    <>
      <section ref={shortContainerRef} className="short-container " >
        {data.map((short) => (
          <Short
            key={short.id}
            shortContainerRef={shortContainerRef}
            short={short}
          />
        ))}
      </section>

      <div className="navigation-container">
        <div onClick={() => { shortContainerRef.current.scrollTo(0, shortContainerRef.current.scrollTop - window.innerHeight );}}
          className="nav-up" >
          ^
        </div>
        <div onClick={() => { shortContainerRef.current.scrollTo( 0, shortContainerRef.current.scrollTop + window.innerHeight );}}
          className="nav-down" >
          v
        </div>
      </div>
    </>
  );
}

export default ShortContainer;
