
import "@pqina/flip/dist/flip.min.css";
import EventCountDown from "./EventCountDown.js";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
// params = {'HTTP_CONTENT_LANGUAGE': self.language}
let headers = {'-H': 'accept: application/json'}

export default function Main() {
                  
  const dateStr = "2024-08-08T00:00:00+05:30";
                  // "2024-08-12T13:16:22+05:30"
  let newdate=new Date()
  const londonTime = newdate.toLocaleString("en-GB", {timeZone: "Asia/Kolkata"});
  console.log(londonTime,'londonTime');
                   
  const curDate = new Date();
  const targetDate = new Date(dateStr);
  // console.log(targetDate.getTime() - curDate.getTime(),'-=-=-=-=');
  let now=new Date()
  
  const [enddate, setenddate] = useState({
    "id": 3,
    "Orderid": "20240730162156357",
    "Date": "2024-07-06T00:00:00+05:30",
    "Time": "sun",
    "Name": "2024-08-07T00:00:00+05:30",
})

const [WeekDayssss, setWeekDays] = useState(enddate.Date)
  // var todayDate=now.getFullYear()+"-"+now.getMonth().toString().padStart(2, "0")+"-"+now.getDate().toString().padStart(2, "0")
  // var todaytime=now.getHours().toString().padStart(2, "0")+":"+now.getMinutes().toString().padStart(2, "0")+":"+now.getSeconds()

  // console.log(todayDate+"T"+todaytime+'+01:00');
  // setenddate(todayDate+"T"+todaytime+'01:00')
  let timerr=document.getElementById('timerr')


  useEffect( () => {
    
    axios.get("https://ayurveda.pythonanywhere.com/ModelApi/v1/ReceivedOrderViewSet/3/")
    .then((res) => { 
      setenddate(res.data)
      let mydate=new Date()
      let alldata={}
      alldata=res.data
      console.log(alldata,'alldata');
      const dayName = (date, locale) => date.toLocaleDateString(locale, { weekday: "short" });
    // console.log(,'mydate');
      let weekdayname=dayName(new Date())
      let ShowDate=new Date(alldata.Name)
      let todayDate=mydate
      let EEEEndDate=new Date(alldata.Date)
      let Weekname=weekdayname
      console.log(Weekname,'Weekname');

      let todaytimeZoneFormat=todayDate.toISOString().split('T')[0]+"T23:59:30+05:30"
      

      console.log(todaytimeZoneFormat,'f');
    if (alldata.Time==='WeekDayname')
      {
      console.log('WeekDayname is Avialble');
      
          if (todayDate>=ShowDate){

            if (todayDate<=EEEEndDate) {
              setWeekDays(alldata.Date)
              timerShowHide('block')
            }
            else{
              // console.log({'ShowDate':ShowDate,'todayDate':todayDate,'EEEEndDate':EEEEndDate});
              console.log("i am Else 2");
              timerShowHide('none')
            }
        }
    }
    
    else{
      console.log(alldata.Time,'alldata.Time',weekdayname);
      if (alldata.Time===weekdayname) {
        setWeekDays(todaytimeZoneFormat)
        timerShowHide("block")
      }
      else{
        console.log("I am Else 1");
        timerShowHide('none')
      }
      // enddate.Date=todaytimeZoneFormat

      // console.log(enddate,'enddate');
        // return 'hidetimer'
        
        // timerShowHide('block')
    }

})
.catch((err) => { 
  let tim=document.getElementById('timerr')
    tim.style.display='none'
});
  
    // https://timeapi.io/api/TimeZone/zone?timeZone=Asia/Kolkata
    // http://worldtimeapi.org/api/timezone/Asia/Kolkata
    // console.log(todayDate+"T"+todaytime+'+05:30','todayDate+"T"+todaytime')
  }, [WeekDayssss])


  let EndDatemyAPI=new Date(enddate.Date)
  // console.log(EndDatemyAPI.getHours(),enddate.Date,'EndDatemyAPI');

  
 function timerShowHide(showhide) {
  timerr.style.display=`${showhide}`;

 }
  return (
   <>
     <div id="timerr"  className="mb-4 text-center bg-warning" style={{display:"none"}}>
          <h1 className="text-primary">
          <strong>{enddate.Mobileno}</strong></h1>
           <EventCountDown value={WeekDayssss} />
          <h5 className="bg-primary text-white mt-2  mb-2">{enddate.Address}</h5>
    </div>
   </>
  );
}
