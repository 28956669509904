import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './CSS/HomeRemedies.css'

const HomeRemedies = () => {
  const [HomeRemediesDatas, setHomeRemediesDatas] = useState([])
  const LoadData=()=>{
    axios.get("https://ayurveda.pythonanywhere.com/ModelApi/v1/MyUpcharViewSet/")
    .then((res)=>{
      setHomeRemediesDatas(res.data.reverse())
      // console.log((res.data));
    })
    .catch((err)=>{
      console.log(err,'MY ERROR');
    })
  }

  useEffect(() => {
    
    LoadData()
    
    // console.log(HomeRemediesDatas,'HomeRemediesDatas')
    window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
  }, [])




  function myFunction() {
    var input, filter, table, tr, td, i, txtValue;
    input = document.getElementById("myInput");
    filter = input.value.toUpperCase();
    table = document.getElementById("myTable");
    tr = table.getElementsByTagName("div");
    for (i = 0; i < tr.length; i++) {
      td = tr[i].getElementsByTagName("i")[0];
      if (td) {
        txtValue = td.textContent || td.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = "";
        } else {
          tr[i].style.display = "none";
        }
      }
    }
  }
  
  return (
    <>
    <center style={{marginTop:"7rem"}}>
    <div className="container p-0 m-0 " style={{width:"90%" ,marginTop:"10rem"}}>
    <center><h1  className='bg-success rounded-5 text-white w-100 mb-2'> </h1></center>
    
    <div className="container-fluid mb-5">
        <input className='form-control w-75' type="text" id="myInput" onKeyUp={myFunction}  placeholder="बीमारी के अनुसार ढूंढे | Search by disease" title="Type in a name" />
    </div>
    <div id="myTable" className="row" style={{width:"100%"}}>

        {HomeRemediesDatas.map(((item,index)=>

          <div className="col-md-3 col-5  productaa p-0" key={index} >
              <Link to={'/HomeRemediesViews/'+item.id} className='tdn'>
                <div className="text-white homeRmCard " style={{width: '100%'}}>
                      <img src={item.ItemPhoto} width="100%" height='140px' className="card-img-top" alt="" />
                      <div className='textpedding'>
                          <i className='text-white' >{item.DiseaseName}</i>
                          <br />
                          से बचने के लिए
                          <br />
                          <p style={{fontSize:"1.5rem", fontWeight:"bolder"}}>{item.Ingredientnames}</p>
                      </div>
                </div>
              </Link>
            </div>
        
        ))}
        
        </div>
    </div>
    </center>
    </>
  )
}

export default HomeRemedies













