import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import './CSS/BlogExplorer.css'
const BlogExplorer = () => {
    const useparms=useParams();

    // console.log(useparms.id,'useparms.id');
   useEffect(() => {
     
      axios.get('https://ayurveda.pythonanywhere.com/ModelApi/v1/BlogViewSet/'+useparms.id)
      .then((res)=>{
        // console.log(res.data);
        setOneData(res.data)
      })
      
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
   }, [])
   const [OneData, setOneData] = useState({})
      console.log(OneData,'OneData');
  return (
    <>
     <h1 className='' style={{background: "linear-gradient(90.9deg, rgb(255, 187, 187) -8.8%, rgb(255, 220, 177) 12.4%, rgb(255, 249, 177) 35.6%, rgb(220, 255, 177) 59.6%, rgb(177, 218, 255) 84%, rgb(222, 177, 255) 107.9%)",marginTop:'5rem'}}>
          {OneData.BlogTitle}
      </h1>
      <div className='ResponsiveView m-auto  ' style={{background: "linear-gradient(90.9deg, rgb(255, 187, 187) -8.8%, rgb(255, 220, 177) 12.4%, rgb(255, 249, 177) 35.6%, rgb(220, 255, 177) 59.6%, rgb(177, 218, 255) 84%, rgb(222, 177, 255) 107.9%)",marginTop:'5rem'}}>
     

    <div className="container pt-3" >
        <div className="card">
            <div className="row">
                <div className="col-md-6">
                      <img src={OneData.Photo} alt=""  width={'100%'}/>
                </div>
                <div className="col-md-6 m-auto">
                    
                      <label className='fontSize'>{OneData.BlogDiscription}</label>
                </div>
            </div>
        
        </div>
        <div className="row mt-3" style={{color:'black'}} align='left'>
            <div className="col-10" dangerouslySetInnerHTML={{__html: OneData.HTML}}>
                
            </div>
        </div>
    </div>
      </div>
      
    
    </>
  )
}

export default BlogExplorer

