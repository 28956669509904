import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'



let paragraphstyle={
  WebkitLineClamp:3,
  WebkitBoxOrient:"vertical",
  overflow:'hidden',
  display:'-webkit-box',
  fontSize:"10px"
}

const Card = ({BlogTitle,BlogDiscription,Photo,isOpen,setisOpen,id}) => {
  
  const usenavigate=useNavigate()
  
  return (
    <>
    <center>
    <div className="row p-0 m-1 mb-4 border rounded"  style={{width:"90%"}} onClick={()=> usenavigate("/BlogExplorer/"+id)}>
          <i className='bg-success text-white w-100 rounded-top'>{BlogTitle}</i>
          <div className="col-4 m-0 p-0">
              <img src={Photo} height={'100%'} className="card-img-top w-100 p-0 align-center" alt="..."/>
          </div>
          
          <div className="col-8">
              <div className=" m-3 p-0" >
                  <div className="card-body">
                        <p className="card-text" style={isOpen ? null  :  paragraphstyle}>{BlogDiscription}</p>
                        <Link  onClick={()=> setisOpen(!isOpen)} className='btn btn-success'>{isOpen ? 'Read Less'  :  'Read More'}</Link>
                        
                  </div>
              </div>
          </div>
    </div>
    </center>
    
    

    </>
  )
}

export default Card
