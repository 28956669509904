import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { QRCode } from 'react-qrcode-logo';
import { useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2';






function Buyarea() {
    const useparams=useParams()
    // console.log(useparams.id);

    const [Data, setData] = useState({})
   
    useEffect(()=>{
        axios.get('https://ayurveda.pythonanywhere.com/ModelApi/v1/ProductsViewSet/'+useparams.id)
        .then((res)=>{
            setData(res.data)
            setTotalPrice(res.data.Price);
            // console.log(res,'D');
            // setTotalPrice(parseInt(res.data.Price)* parseInt(OrderDetails.Qty))
            console.log('USEEFFECT');
            Country.current.value='India'
        })

        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });

        // axios.get('https://generativelanguage.googleapis.com/v1beta/models/gemini-1.5-flash-latest:generateContent?key=AIzaSyAiEAQ0E68P6fyf-sADjkJix773zxjW8EU')
        // .then((res)=>{
        //    console.log(res,'DateTime');
        // })
    },[])



    const [QtyState, setQtyState] = useState(1)
    // console.log(QtyState,TotalPrice,'Qty Total');
    const [TotalPrice, setTotalPrice] = useState(Data.Price)
    const [Price, setPrice] = useState(Data.Price)

    
    

    const handler=(e)=>{
        e.preventDefault();
        const {name,value}=e.target; // DISTRUCTIONG
        // setOrderDetails({...OrderDetails,[name]:value})
        setTotalPrice(parseInt(Data.Price)* parseInt(QtyRef.current.value))
        setQtyState(QtyRef.current.value)
        // console.log(OrderDetails,'OrderDetails');
    }

    let QtyRef =useRef();
    let TotalValueRef=useRef();
    let Pincode=useRef();
    let Address=useRef();
    let Landmark=useRef();
    let Name =useRef();
    let Mobileno=useRef()
    let City=useRef();
    let State=useRef();
    let Country=useRef()
    let PaymentYESNO=useRef()
    
    // let PaidCod=useRef()
    const [PaidCod, setPaidCod] = useState('')
    const [qrCodeData, setqrCodeData] = useState('')
    let PageHide=useRef()
   
    // const [OrderDetails, setOrderDetails] = useState({
        
    // })

    // console.log(TotalPrice,'TotalPrice');
    var now = new Date()
    // console.log(now,'nownownow');
    // var todaytime= now.getHours()+":"+now.getMinutes()+":"+now.getSeconds();
    // var todayDate=now.getFullYear()+"-"+(now.getMonth()+1)+"-"+now.getDate().toString().padStart(2, "0");
    var todayDate=now.toLocaleDateString()
    var todaytime=now.toLocaleTimeString()
    var OrderID = now.getFullYear()+""+(now.getMonth()+1).toString().padStart(2, "0")+""+now.getDate()+""+now.getHours()+""+now.getMinutes()+""+now.getSeconds()+""+now.getMilliseconds().toString();
    const [Countryy, setCountryy] = useState('India')
   let StateList=["Andhra Pradesh","Arunachal Pradesh ","Assam","Bihar","Chhattisgarh","Goa","Gujarat","Haryana","Himachal Pradesh","Jammu and Kashmir","Jharkhand","Karnataka","Kerala","Madhya Pradesh","Maharashtra","Manipur","Meghalaya","Mizoram","Nagaland","Odisha","Punjab","Rajasthan","Sikkim","Tamil Nadu","Telangana","Tripura","Uttar Pradesh","Uttarakhand","West Bengal","Andaman and Nicobar Islands","Chandigarh","Dadra and Nagar Haveli","Daman and Diu","Lakshadweep","Delhi (National Capital)","Puducherry"]
    // console.log(now,'d----------------------');
   
    // console.log(typeof(OrderID),OrderID,'OrderID');
    let usenavigate=useNavigate()



       
       
    
    function SaveOrder(e) {
        e.preventDefault();

        let MyOrder={
            Orderid:OrderID,
            Date:todayDate,
            Time:todaytime,
            Name:Name.current.value,
            Mobileno:Mobileno.current.value,
            Address:Address.current.value,
            City:City.current.value,
            State:State.current.value,
            Landmark:Landmark.current.value,
            Pincode:Pincode.current.value,
            Qty: QtyState,
            Price:Data.Price,
            Total:TotalPrice,
            Paymentmode:PaidCod,
            PaymentStatus:"Pending",
            Productname:Data.Name,
            OrderPick:'Hold',
            OrderStatus:'WatingPickup',
        }

        if (MyOrder.Paymentmode === 'COD'){
                console.log("Thanks For Shopping");
                MyOrder.PaymentStatus='Panding COD'
                console.log(MyOrder,'---------------------');
                axios.post('https://ayurveda.pythonanywhere.com/ModelApi/v1/ReceivedOrderViewSet/',MyOrder)

                Swal.fire({
                    title: "Thanks For Shopping",
                    // text: "You clicked the button!",
                    icon: "success" });

                usenavigate('/ThanksShopping/'+MyOrder.Orderid)
        }
        else if (MyOrder.Paymentmode==='OnlinePayment')
        {
            
            setqrCodeData(`upi://pay?pa=8700800328@okbizaxis&am=${MyOrder.Total}.00&mc=5111&aid=uGICAgICdu4S0EQ&ver=01&mode=01&tr=${MyOrder.Orderid+'_'+MyOrder.Mobileno}`)

            console.log('Qr Code');
            // console.log(','PageHide');
            PageHide.current.style.display = "none";
            Paynow.current.style.display='block';
      
            
            // console.log(MyOrder,'MyOrder');
           
        }
        else{
            console.log("Somthing Error Please Try Again");
        }
        // console.log(MyOrder,'MyOrder');
        // console.log(,'console.log(qrCodeData);');
        // console.log(OrderDetails,'submit order details');
    }

    let Paynow=useRef()
        
    function PaynowFunction(params) {
        PaymentYESNOStatus()
    }

function PaymentYESNOStatus(p){
    setTimeout(() => {
        PaymentYESNO.current.style.display='block'
    }, 10000);
}

function Thanks(e,n) {
    Swal.fire({
        title: e ,
        // text: "O",
        icon: n
    });
    usenavigate('/')
}
  
    return(
<>

<div className="container-fluid" style={{marginTop:'5rem'}}>

    <div className="row" ref={PageHide}>
        <div className="col-md-3">
        <center>
            <div className='card rounded-5 mt-2' >
                <img src={Data.Img} width={'100%'} alt="" />
                
                <h4>{Data.Name}</h4>
                <hr  style={{marginTop:'0',marginBottom:"0"}}/>
                
                <h3>Price : {Data.Price}</h3>
                <h3>
                Qty: <select id='1'  onChange={handler} ref={QtyRef} name='Qty' style={{width:"50%"}}>
                        <option value="1" >1</option>
                        <option value="2" >2</option>
                        <option value="3" >3</option>
                        <option value="4" >4</option>
                        <option value="5" >5</option>
                    </select>
                </h3>
            <hr />
                <h4 id='checkTotal' ref={TotalValueRef} onChange={handler}>Total ₹ {TotalPrice}</h4>
                {/* <h4>Total ₹ {parseInt(Data.Price)*parseInt(QtyRef.current.value)}</h4> */}
                        
            </div>
        </center>
        </div>



        <div className="col-md-9">
            <form action="" onSubmit={SaveOrder}>
                <div className="input-group mb-3 mt-2 ">
                    <span className="btn btn-warning input-group-text border-dark">Name *</span>
                    <input type="text"  name='Name' ref={Name} className='form-control border-dark' required/>
                </div>
                
                <div className="input-group mb-3 mt-2 ">
                        <span className="btn btn-warning input-group-text border-dark">Mobile no *</span>
                        <input type="text"   name='Mobileno' ref={Mobileno} className='form-control border-dark' required/>
                </div>
                
                <div className="input-group mb-3 mt-2 ">
                    <span className="btn btn-warning input-group-text border-dark">Address *</span>
                    <input type="text"   name='Address' ref={Address} className='form-control border-dark' required />
                </div>
                <div className="input-group mb-3 mt-2 border-dark">
                        <span className="btn btn-warning input-group-text border-dark">City/Town *</span>
                        <input type="text" ref={City} className="form-control border-dark " placeholder="City" />
                </div>

                <div className="input-group mb-3 mt-2 ">
                        <span className="btn btn-warning input-group-text border-dark">State *</span>
                        <input type="text" list='statte' name='State' ref={State}  className="form-control border-dark " placeholder="State" />
                        <datalist id='statte'>
                        {StateList.map((item)=>(
                            <option value={item} key={item}>{item}</option>
                        ))}
                        </datalist>
                </div>

                <div className="input-group mb-3 mt-2 ">
                        <span className="btn btn-warning input-group-text border-dark">Country *</span>
                        <input type="text" name='Country' ref={Country}  className="form-control border-dark"  placeholder="Country" />
                </div>

                <div className="input-group mb-3 mt-2">
                    <span className="btn btn-warning input-group-text border-dark" >Pincode *</span>
                    <input type="text"   name='Pincode' ref={Pincode} className='form-control border-dark' required/>
                </div>
            
                <div className="input-group mb-3 mt-2 ">
                    <span className="btn btn-warning input-group-text border-dark">Landmark</span>
                    <input type="text"   name='Landmark' ref={Landmark} className='form-control border-dark' />
                </div>
        
                            <div className="row">
                                <div className="col-md-12">
                                <div className="input-group mb-3 mt-2 ">
                    <label htmlFor="" className='form-control border-dark btn btn-warning ' > 
                            <input className="form-check-input" type="radio" name="PaidCod"  value={'OnlinePayment'} onClick={()=>setPaidCod('OnlinePayment')}  required/>
                    </label>
                    <span className=" border-dark btn btn-warning">Prepaid (Online Payment) *</span>
            
                </div>
            
                <div className="input-group mb-3 mt-2 ">
                        <label htmlFor="" className='form-control border-dark btn btn-warning'>
                            <input className="form-check-input" type="radio" name="PaidCod"  value={'COD'}  onChange={()=>setPaidCod('COD')} required/>
                        </label>
                    
                        <label className=" border-dark btn btn-warning" htmlFor="PayedCOd"> COD (Cash On Delivey) *</label>
                </div>
                                </div>
                            </div>

            <br />
            <input type="submit" className='btn btn-primary w-100 rounded-0'  value={'Place Order'} />
            </form>
        
        <br />
   
        </div>
    </div>



   <div ref={Paynow} style={{display:"none"}}>
        <QRCode value={qrCodeData}/>
        <br />
        <a href={qrCodeData} className='btn btn-warning' onClick={PaynowFunction}>Click to Pay (Upi Payment)</a>
   </div>
    

   <div style={{display:"none"}} ref={PaymentYESNO}>
        <h1 align='center'> If Payment Success (Press "Yes")</h1>
        <button className='btn btn-success' onClick={()=>Thanks('Thanks For Shopping <br/>  Please Wait <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className=" rounded-circle bg-success bi bi-telephone-fill" viewBox="0 0 16 16">  <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"/></svg>  Callback','success')}>Yes</button> &ensp;
        <button className='btn btn-danger' onClick={()=>Thanks('Please Try After Some Time','warning')}>No</button>
   </div>

</div>

</>
    )
}
export default Buyarea