import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

const HomeRemediesViews = () => {
  let useparams=useParams()
  const [Ilag, setIlag] = useState({})

  useEffect(() => {
    axios.get('https://ayurveda.pythonanywhere.com/ModelApi/v1/MyUpcharViewSet/'+useparams.id)
    .then((res)=>{
        // console.log(res.data);
        setIlag(res.data)
    })
    .catch((error)=>{
        console.error(error,'error');
    })

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, [])
  
    

  return (
    <>
        {Ilag?  
        <>
        <div className="row" style={{marginTop:"5rem"}}>
          <div className="col-md-3"></div>
          <div className="col-md-6">
          <div className=' rounded-5 text-white'>
            <div className="container-fluid bg-primary text-white p-2 mb-3  rounded-5">
                <h2 className='bg-success rounded-5'>इलाज (Treatment)</h2>
                <hr />
                <img className='mb-2 rounded-5' width={'100%'} src={Ilag.ItemPhoto} alt=""  />
                <h1>{Ilag.Ingredientnames}</h1>
            
            </div>
            <div className='container text-dark'>
            <h2>इस्तेमाल कैसे करे <br />How to Use</h2>
            
            <hr />
            {Ilag.HowToUse}
            </div>  
        </div>
          </div>
          <div className="col-md-3"></div>
        </div>

        
        
        </>
        
        :<>
            Please Wait  
        </>}
    </>
  )
}

export default HomeRemediesViews
