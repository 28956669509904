import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom';

const ThanksShopping = () => {
  const [data, setdata] = useState();
  let SearchArea=useRef()
  let FindedID
  let useparams=useParams()
  let orderIDurl=useparams.id
  const [FindedData, setFindedData] = useState({})

  const Loaddata=()=>{
          axios.get("https://ayurveda.pythonanywhere.com/ModelApi/v1/ReceivedOrderViewSet/")
          .then((res) => {
            setdata(res.data)
            // console.log(res.data); 
            SearchArea.current.value=orderIDurl
            console.log('0--0-0-0-0-0-0-0-0');
          })
          .catch((err) => {
            console.log(err,'--------------error----------');
          });
  }

  useEffect(() => {
    Loaddata();
    // CheckData();

    
window.scrollTo({
  top: 0,
  left: 0,
  behavior: "smooth"
});
  }, [])
  


  
  function   CheckData(params) {
        // console.log(,'data.Orderid');
    // console.log(data.length,'data.length');
    for (let i = 0; i < data.length; i++) {
      const element = data.map((item)=> item.Orderid)[i]

        if (element===SearchArea.current.value) {
            FindedID=data.map((item)=> item.id)[i]
            console.log(FindedID,'FindedID');
            setFindedData(data.map((item)=> item)[i])

          // console.log(data.map((item,index)=> item)[i],'CheckBox FInded Data');
        }}
  }

  // console.log(data,'data');
  // console.log((1000+1200+10+20+199+1600+10+20)-3400,'---len');
  return (
    <>
        <div style={{marginTop:'10rem'}}>
            <h1 className='m-auto' > ThanksShopping</h1>
       </div>
       <br />



       <div className="container">
       <h1 className='bg-dark text-white'>Check Your Order Status</h1>

        <div className="row">
          <div className="col-md-12">
              <div className="input-group mb-3 mt-2 ">
                  <span className="input-group-text border-dark">Orderid *</span>
                  <input type="text" ref={SearchArea} className='form-control border-dark' />
              </div>
              <input type="submit"  value={'View Status'} onClick={CheckData} className='p-2 text-dark'/>
          </div>
        </div>
<hr />



        <div className="row">

            <table className='mb-3'>
              <thead>
                <tr>
                  <th>S.no</th>
                  <th>Name</th>
                  <th>Order Date</th>
                  <th>City</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>1</td>
                  <td>{FindedData.Name}</td>
                  <td>{FindedData.Date}</td>
                  <td>{FindedData.City}</td>
                </tr>
              </tbody>

</table>
<hr className='text-primary' /><hr className='text-warning' /><hr  className='text-success'/>
<table className='table '>
              <thead>
                  <tr>
                    <th>Product Name</th>
                    <th>Qty</th>
                    <th>Total Price</th>
                  </tr>
              </thead>

              <tbody>
                <tr>
                  <td>e</td>
                  <td>{FindedData.Qty}</td>
                  <td>{FindedData.Price}</td>
                </tr>
              </tbody>
            </table>
        </div>
       </div>
    </>
  )
}

export default ThanksShopping