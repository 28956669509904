import axios from 'axios'
import React, { useRef } from 'react'

const SaleStart = () => {
    let StartTimer=useRef()
    let StartTimerTime=useRef()

    let EndDate=useRef()
    let EndDateTime=useRef()
    let Notes1=useRef()
    let Notes2=useRef()
    let WeekName =useRef()

    function showdata(params) {
        params.preventDefault();
        let data={
            "id":3,
            "Name":StartTimer.current.value+"T"+StartTimerTime.current.value+":00+05:30",
            "Date":EndDate.current.value+"T"+EndDateTime.current.value+":00+05:30",
            "Time":'WeekDayname',
            "Mobileno":Notes1.current.value,
            "Address":Notes2.current.value
        }
        console.log(data,'StartTimer');

        axios.put('https://ayurveda.pythonanywhere.com/ModelApi/v1/ReceivedOrderViewSet/3/',data)
        .then((res) => {
            console.log(res,'RES UPLOAD');
        })
        .catch((err) => {
            console.log(err,'err-SaleStartPAge');
        });
    }

    
    
    const TimeWise=()=> {
        let timewiseee=document.getElementById('timewiseee');
        let weeknamewise=document.getElementById('weeknamewise');
        // console.log('TimeWise');
        // console.log(timewiseee,'timerwise');

       timewiseee.style.display='block'
        weeknamewise.style.display='none'
        
    }
    const WekeWise=()=> {
        let timewiseee=document.getElementById('timewiseee');
        let weeknamewise=document.getElementById('weeknamewise');
        // console.log(weeknamewise,'WekeWise');

         weeknamewise.style.display='block'
        timewiseee.style.display='none'
        
        
    }

    function showdataWeek(params) {
        params.preventDefault();
        let dataaaa={
            "id":3,
            "Time":WeekName.current.value,
            "Mobileno":Notes1.current.value,
            "Address":Notes2.current.value
        }
    console.log(dataaaa,'data--wrrk');

    axios.put('https://ayurveda.pythonanywhere.com/ModelApi/v1/ReceivedOrderViewSet/3/',dataaaa)
        .then((res) => {
            console.log(res);
        })
        .catch((err) => {
            console.log(err,'err-SaleStartPAge');
        });
        
    }

  return (
    <>
        <div style={{marginTop:"7rem"}}>

        <h1>Select TimeDate Wise ya Week name wise</h1>
        <button onClick={TimeWise}>----Time-Date---</button>&ensp;
        <button onClick={WekeWise}>----WeekName----</button>
        <br /><br />


            <form className='w-75 text-center m-auto' >
                <div id='timewiseee' style={{display:'none'}}>
                        <div class="input-group mb-3">
                                <span class="input-group-text" >Show Timer</span>
                                <input ref={StartTimer} type="date" class="form-control" placeholder="Show Timer"  />
                                <input ref={StartTimerTime} type="time" class="form-control" placeholder="End Date"  />
                        </div>
                        
                        <div class="input-group mb-3">
                                <span class="input-group-text" >End Date</span>
                                <input ref={EndDate} type="date" class="form-control" placeholder="End Date"  />
                                <input ref={EndDateTime} type="time" class="form-control" placeholder="End Date"  />
                        </div>

                        <div class="input-group mb-3">
                                <span class="input-group-text" >Top Note</span>
                                <input ref={Notes1} type="text" class="form-control" placeholder="Top Note"  />

                                <span class="input-group-text" >Bottom Notes</span>
                                <input ref={Notes2} type="text" class="form-control" placeholder="Bottom Note"  />
                        </div>
                        <input type="submit" className='w-50 bg-warning' onClick={showdata} />
                </div>

                <div id='weeknamewise' style={{display:'none'}}>

                        <input ref={WeekName}  type="text" class="form-control w-100" placeholder="Week Day Name short (Sun Mon Tue)" /><br />
                        <div class="input-group mb-3">
                                <span class="input-group-text" >Top Note</span>
                                <input ref={Notes1} type="text" class="form-control" placeholder="Top Note" />

                                <span class="input-group-text" >Bottom Notes</span>
                                <input ref={Notes2} type="text" class="form-control" placeholder="Bottom Note"  />
                        </div>

                        <input type="submit" className='w-50 bg-warning' onClick={showdataWeek}  />
                </div>


                
            </form>


        </div>
    </>
  )
}

export default SaleStart