import React from 'react'

const StorePolicy = () => {
  return (
   <>
  <div className="container">
  <div class="text-start" style={{marginTop:'5rem'}}>
   <p class="h2" mdn-text="">Store Policies</p><p class="css-1yp3xav" mdn-text="">
   <div>
   <h3>Account</h3>
<br />
    1.  Do I have to create an account to make a purchase?
    You don't need any registration or account creation to browse through our store. However, you will have to login with your Mobile No. via an OTP to allow us to help.
    <br />
    2.  How can I see my orders?
    You can go to the My Orders section on the storefront and see your orders.
    <br />
    3.  Is ordering on your website secure?
    Absolutely. We value your privacy and uphold your data to the highest safety standards. All transactions are completely secure and processed with secure paths via reputed payment gateways following global standards.
    All information is access controlled and cannot be released.
    <br /><br />
    <h3>Shipping</h3>
    <br />
    4.  Do you deliver Pan-India?
    Yes. We serve more than 28000 pin codes across India.
    <br />
    5.  What are the general shipping or delivery timelines?
    We take 24-48 hours to process your order and prepare it for logistics partner to pick-up. The logistics partner takes 3-4 days to deliver the product post pick-up.
    <br />
    6.  What are the shipping charges associated with an order?
    The shipping charges for your order will be calculated and displayed at checkout.
    <br />
    7.  How do I track my order?
    You can track your orders from our website and separately you will get email and Whatsapp notifications from our logistics partner.
    <br /><br />
    <h3>Payments</h3>
    <br />
    8.  What payment methods do you accept?
    You can pay for your orders using any of the payment methods below-
    1) Cards (visa/Mastercard/Rupay/Discover/Amex)
    2) UPI
    3) Net Banking
    4) Wallets
    5) BNPL (Buy Now, Pay later)
    <br />
    9.  Do you offer COD (Cash on Delivery)?
    We currently don't offer COD. All other online payment methods are available as mentioned in above FAQ.
    <br />
    10.  Will I get automatic refund for failed payments?
    Yes. You will get an automated refund into your original payment source within 7-10 working days.
    <br />
    11.  What if I have issues with my order?
    Please reach out to us from the Whatsapp icon on storefront or contact us with the details mentioned in the "reach out to Us" Section. We will try resolve all your issues on priority.</div></p></div>
    <br />
  </div>
   </>
  )
}

export default StorePolicy