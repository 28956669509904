import React, { useRef, useEffect, useState } from "react";

import { StyledTimeContainer } from "./event_styles.ts";
import Tick from "@pqina/flip";

const EventCountdown = ({ value }) => {
  const divRef = useRef();
  const tickRef = useRef();

  const [tickValue, setTickValue] = useState(value);

  // Make the Tick instance and store it in the refs
  useEffect(() => {
    const didInit = (tick) => {
      tickRef.current = tick;
    };

    const currDiv = divRef.current;
    const tickValue = tickRef.current;
    Tick.DOM.create(currDiv, {
      value,
      didInit
    });
    return () => Tick.DOM.destroy(tickValue);
  }, [value]);

  // Start the Tick.down process
  useEffect(() => {
    const counter = Tick.count.down(value, {
      format: ["d", "h", "m", "s"]
    });

    // When the counter updates, update React's state value
    counter.onupdate = function (value) {
      setTickValue(value);

      
    };

    return () => {
      counter.timer.stop();
    };
  }, [value]);

  // When the tickValue is updated, update the Tick.DOM element
  useEffect(() => {
    if (tickRef.current) {
      tickRef.current.value = {
        days: tickValue[0],
        hours: tickValue[1],
        mins: tickValue[2],
        secs: tickValue[3]
      };
    }
  }, [tickValue]);

  return (
    <div className="tick">
      <div data-repeat="true" data-layout="horizontal fit">
        <div className="tick-group" style={{ fontSize: 24 }}>
          <div ref={divRef} style={{ display:"flex"}}>
            <StyledTimeContainer style={{background: 'linear-gradient(125deg, #FDFF9C 0%, #0500FF 100%), linear-gradient(180deg, #D3D3D3 0%, #161616 100%), linear-gradient(310deg, #00F0FF 0%, #00F0FF 20%, #0017E3 calc(20% + 1px), #0017E3 40%, #000F8F calc(40% + 1px), #000F8F 70%, #00073F calc(70% + 1px), #00073F 100%), linear-gradient(285deg, #FFB6B9 0%, #FFB6B9 35%, #FAE3D9 calc(35% + 1px), #FAE3D9 45%, #BBDED6 calc(45% + 1px), #BBDED6 65%, #61C0BF calc(65% + 1px), #61C0BF 100%)',backgroundBlendMode: "overlay, overlay, exclusion, normal"}}>
              <div
                  data-key="days"
                  data-repeat="true"
                  data-transform="pad(00) -> split -> delay">
                
                  <span data-view="flip"></span>
              </div>
              <p
                  className="tick-text-inline"
                  style={{ color: "black", margin: 0, fontSize: 20 }}>
                DAY
              </p>
            </StyledTimeContainer>

            <StyledTimeContainer style={{background: 'linear-gradient(125deg, #FDFF9C 0%, #0500FF 100%), linear-gradient(180deg, #D3D3D3 0%, #161616 100%), linear-gradient(310deg, #00F0FF 0%, #00F0FF 20%, #0017E3 calc(20% + 1px), #0017E3 40%, #000F8F calc(40% + 1px), #000F8F 70%, #00073F calc(70% + 1px), #00073F 100%), linear-gradient(285deg, #FFB6B9 0%, #FFB6B9 35%, #FAE3D9 calc(35% + 1px), #FAE3D9 45%, #BBDED6 calc(45% + 1px), #BBDED6 65%, #61C0BF calc(65% + 1px), #61C0BF 100%)',backgroundBlendMode: "overlay, overlay, exclusion, normal"}}>
              <div
                data-key="hours"
                data-repeat="true"
                data-transform="pad(00) -> split -> delay"
              >
                <span data-view="flip"></span>
              </div>
              <p
                className="tick-text-inline"
                style={{ color: "black", margin: 0, fontSize: 20 }}
              >
                HOUR
              </p>
            </StyledTimeContainer>

            <StyledTimeContainer style={{background: 'linear-gradient(125deg, #FDFF9C 0%, #0500FF 100%), linear-gradient(180deg, #D3D3D3 0%, #161616 100%), linear-gradient(310deg, #00F0FF 0%, #00F0FF 20%, #0017E3 calc(20% + 1px), #0017E3 40%, #000F8F calc(40% + 1px), #000F8F 70%, #00073F calc(70% + 1px), #00073F 100%), linear-gradient(285deg, #FFB6B9 0%, #FFB6B9 35%, #FAE3D9 calc(35% + 1px), #FAE3D9 45%, #BBDED6 calc(45% + 1px), #BBDED6 65%, #61C0BF calc(65% + 1px), #61C0BF 100%)',backgroundBlendMode: "overlay, overlay, exclusion, normal"}}>
              <div
                data-key="mins"
                data-repeat="true"
                data-transform="pad(00) -> split -> delay"
              >
                <span data-view="flip"></span>
              </div>
              <p
                className="tick-text-inline"
                style={{ color: "black", margin: 0, fontSize: 20 }}
              >
                MIN
              </p>
            </StyledTimeContainer>

            <StyledTimeContainer style={{background: 'linear-gradient(125deg, #FDFF9C 0%, #0500FF 100%), linear-gradient(180deg, #D3D3D3 0%, #161616 100%), linear-gradient(310deg, #00F0FF 0%, #00F0FF 20%, #0017E3 calc(20% + 1px), #0017E3 40%, #000F8F calc(40% + 1px), #000F8F 70%, #00073F calc(70% + 1px), #00073F 100%), linear-gradient(285deg, #FFB6B9 0%, #FFB6B9 35%, #FAE3D9 calc(35% + 1px), #FAE3D9 45%, #BBDED6 calc(45% + 1px), #BBDED6 65%, #61C0BF calc(65% + 1px), #61C0BF 100%)',backgroundBlendMode: "overlay, overlay, exclusion, normal"}}>
              <div
                data-key="secs"
                data-repeat="true"
                data-transform="pad(00) -> split -> delay"
              >
                <span data-view="flip"></span>
              </div>
              <p
                className="tick-text-inline"
                style={{ color: "black", margin: 0, fontSize: 20 }}
              >
                SEC
              </p>
            </StyledTimeContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventCountdown;
