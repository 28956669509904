import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

const ProductView = () => {
    const useparams=useParams()
    // console.log(useparams.id,'useparams.id');   
    
  const [DataView, setDataView] = useState({})
 
    useEffect(() => {
     axios.get('https://ayurveda.pythonanywhere.com/ModelApi/v1/ProductsViewSet/')
     //     // axios.get('https://api.nasa.gov/planetary/apod')
       .then((res) => {
         // console.log(res,"MYRES");
        
         console.log(res.data);
     
         res.data.map((item)=> {
           if (item.Name==useparams.id) {
             setDataView(item)
             console.log(item,'item');
             
         } }   )
     // if (res.data.map((item)=> item.Name === 'sas sas asa')) {
     //   console.log('I am Avilable');
     // }
       })
       .catch((err) => {
         console.log(err,'MY ERRR');
         
       });
         
     
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
    }, [])
     
    let Img=document.getElementById("Img1")
    let Img22=document.getElementById("Img2")
    let Img33=document.getElementById("Img3")
    let Img44=document.getElementById("Img4")
    console.log('dd');
    function Img1(params) {
      Img.style.display='block'
      Img22.style.display='none'
      Img33.style.display='none'
      Img44.style.display='none'
        // MainImg.scr='https://i.ibb.co/hV8kVJP/Whats-App-Image-2024-07-21-at-11-03-44-PM-1.jpg'
    }
    

    function Img2(e) {
      Img.style.display='none'
      Img22.style.display='block'
      Img33.style.display='none'
      Img44.style.display='none'
    }

    function Img3(e) {
      Img.style.display='none'
      Img22.style.display='none'
      Img33.style.display='block'
      Img44.style.display='none'
      
    }

    function Img4(e) {
      Img.style.display='none'
      Img22.style.display='none'
      Img33.style.display='none'
      Img44.style.display='block'
    }
    
    
  return (
  <>
    
    <title>{DataView.Name}</title>
    <meta property="og:image" content={DataView.Img} />

  {/* -------------===================-------------- */}

   <div className="container-fluid" style={{marginTop:"5rem"}}>
              <div className="row ">
                <div className="col-3 col-md-1">
                      <img src={DataView.Img} onClick={Img1} width={'100%'} alt="..." className="rounded p-1" />
                      <img src={DataView.Img2} onClick={Img2} width={'100%'} alt="..." className="rounded p-1" />
                      <img src={DataView.Img3} onClick={Img3} width={'100%'} alt="..." className="rounded p-1" />
                      <img src={DataView.Img4} onClick={Img4} width={'100%'} alt="..." className="rounded p-1" />
                </div>

                <div className="col-9 col-md-4">
                      <img className="card-img-top w-100  " src={DataView.Img} id='Img1' width={'100%'}   alt="main" /> 
                      <img src={DataView.Img2} style={{display:"none"}} id='Img2' width={'100%'} alt="..." className="rounded p-1" />
                      <img src={DataView.Img3} style={{display:"none"}} id='Img3' width={'100%'} alt="..." className="rounded p-1" />
                      <img src={DataView.Img4} style={{display:"none"}} id='Img4' width={'100%'} alt="..." className="rounded p-1"  />
                </div>
                
                <div className="col-md-4">
                        <h4 align='left' className="card-title">{DataView.Name}</h4>

                        <hr style={{marginTop:"0",marginBottom:"0"}} />
                        {/* <p className="card-text" style={{marginTop:"0"}}>Butter Point</p> */}

                        <h3 align='left' className="card-text" style={{marginTop:"0",fontFamily:"Amazon Ember"}}>
                            <span className="" style={{color: "red",fontFamily:"Amazon Ember"}}>
                            {((DataView.Mrp-DataView.Price)/DataView.Mrp*100).toFixed(2)}% Off
                                {/* ₹ {DataView.Mrp-DataView.Price} off */}
                            </span> &nbsp;       
                            ₹ <strong>{DataView.Price} </strong>
                            <br />
                            <h5><del><span style={{fontSize:""}}>M.R.P  ₹ {DataView.Mrp}</span></del></h5>    
                        </h3>
                        
                        
                        <hr style={{marginTop:"0",marginBottom:"0"}} />
                        
                        <div align='left'>
                            <p className="card-text" style={{fontSize:'19px',marginBottom:"9px" }}>
                                  <img width="20px" src="https://m.media-amazon.com/images/G/31/A2I-Convert/mobile/IconFarm/trust_icon_free_shipping_81px._CB630870460_.png" alt="..." className="rounded"  />
                                  Free Delivery
                                  {/* <img width="20px" src="https://m.media-amazon.com/images/G/31/A2I-Convert/mobile/IconFarm/icon-returns._CB484059092_.png" alt="..." className="rounded" />
                                  7 Day Return Policy */}
                                  <img width="20px" src="https://m.media-amazon.com/images/G/31/A2I-Convert/mobile/IconFarm/icon-amazon-delivered._CB485933725_.png" className="rounded" />
                                  Home Delivery
                            </p>
                            

                            <label className="text-uppercase " htmlFor="">COD (CASH ON delivery )</label>
                            <label className="  " htmlFor=""> 5 Day Delivery </label>
                            <label className="" htmlFor="">
                                Pincode 
                                <svg width="5%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                {/* <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --> */}
                                <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 256c-35.3 0-64-28.7-64-64s28.7-64 64-64s64 28.7 64 64s-28.7 64-64 64z"/></svg>
                            <input type="text" /></label>
                          
                            <label  htmlFor="">Sold by :
                                <a className="text-decoration-none text-uppercase"  href="">{" Ayurved24 *"}</a>
                            </label>
                                        <br />
                          <label style={{border: '1',color:'green' }}className="fw-bold" htmlFor="">Only {'9'} In Stock</label><br />
                            <dt>Quantity &nbsp;  
                            <select width='40px' height='25px' className="custom-select" name="qty" id="">
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                          </select></dt>
                        </div>

                      <br />
                        {/* <p className="card-text" style={{marginTop:"0"}}>Product Description = {DataView.Description}</p> */}
                        <div className="row p-1">
                                  <a name="" id="" className="col-6 btn btn-primary rounded-0" >ADD TO CART</a>
                                  <a href={"/Buyarea/"+DataView.id} name="" id=""  className="col-6 btn btn-warning rounded-0">BUY NOW</a>
                        </div>
  
          </div>
          </div>
          </div>

          <div className="container mt-5">
                <h2 align='left'>Description</h2><hr />
                <div align='left'  dangerouslySetInnerHTML={{ __html: DataView.Description}} />
          </div>

  </>
  
  )

  
}

export default ProductView