import React from 'react'

const ReturnPolicy = () => {
  return (
   <>
   <div className="container">
   <div class="css-1c02o5u" style={{marginTop:'5rem'}}>
    <p class="h2" mdn-text="">Return Policy</p>
    <div class="css-1arci7b"><div class="css-bp4v2f">
    <p class="css-1yp3xav text-start" mdn-text="">
    <div><br />
    1.  We ensure that our products reach you safely and on-time and currently don't accept returns. In case of queries please contact us with the details mentioned in the "Reach out to Us" Section.
    <br />2.  In case you receive damaged products, please contact us within 24 hours with images and we will resolve this.
    <br />3.  We understand sometimes products don't meet your expectation and we will evaluate if we can offer returns at your location at an additional charge. In such cases, please contact us and we will evaluate and decide if we can offer returns at your location at an additional charge.
    <br />4.  Returns if offered will be at a nominal cost for picking the product from your door step and delivering back to us. This cost will be communicated to you before processing returns.</div></p></div></div></div>
   
   </div>

   </>
  )
}

export default ReturnPolicy